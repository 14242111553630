import React from "react";
import { Col, Row, Image, Container, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ProductContext } from "../context";
import { Icon } from "react-icons-kit";
import { trash2 } from "react-icons-kit/feather";

export default function CheckoutProducts({ handleCartClose }) {
    const history = useHistory();
    const { cart, handleQtyChange } = React.useContext(ProductContext);
    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center mb-3">Termékeim</h3>
                    {cart.length > 0 ? (
                        cart.map((cartItem, idx) => {
                            return (
                                <Container
                                    as={Row}
                                    className="mb-2 checkout-item"
                                    key={idx}
                                >
                                    <Row>
                                        <Col xs={4}>
                                            <Image
                                                src={
                                                    "https:" +
                                                    cartItem.fields.productImage
                                                        .fields.file.url +
                                                    "?w=211&h=139"
                                                }
                                                fluid
                                            />
                                        </Col>
                                        <Col xs={7}>
                                            <Row>
                                                <Col xs={12}>
                                                    <Link
                                                        to={
                                                            "/vasarlas/" +
                                                            cartItem.fields.url
                                                        }
                                                    >
                                                        {
                                                            cartItem.fields
                                                                .productName
                                                        }
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <div className="d-flex justify-content-center stepper-buttons">
                                                        <Button
                                                            variant="outline-dark"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleQtyChange(
                                                                    cartItem.quantity -
                                                                        1,
                                                                    cartItem.sys
                                                                        .id
                                                                );
                                                            }}
                                                        >
                                                            <span>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-dash-lg"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </Button>
                                                        <div>
                                                            <span>
                                                                {
                                                                    cartItem.quantity
                                                                }
                                                                db
                                                            </span>
                                                        </div>
                                                        <Button
                                                            variant="outline-dark"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleQtyChange(
                                                                    cartItem.quantity +
                                                                        1,
                                                                    cartItem.sys
                                                                        .id
                                                                );
                                                            }}
                                                        >
                                                            <span>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-plus-lg"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    {cartItem.fields.price *
                                                        cartItem.quantity +
                                                        " Ft"}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            xs={1}
                                            className="d-flex text-center"
                                        >
                                            <Button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleQtyChange(
                                                        0,
                                                        cartItem.sys.id
                                                    );
                                                }}
                                            >
                                                <Icon icon={trash2} size={24} />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            );
                        })
                    ) : (
                        <p className="text-center">A kosarad jelenleg üres.</p>
                    )}
                </Col>
                <Row>
                    <Col className="d-flex justify-content-end pt-3">
                        <Button
                            onClick={() => {
                                handleCartClose();
                                history.push("/fizetes");
                            }}
                            disabled={cart.length === 0}
                        >
                            Tovább a fizetéshez
                        </Button>
                    </Col>
                </Row>
            </Row>
        </>
    );
}
