import React from "react";
import { ListGroup } from "react-bootstrap";
import { ProductContext } from "../context";
import CartItemWrapper from "./CartItemWrapper";

export default function CartList() {
    const { handleQtyChange, cart } = React.useContext(ProductContext);

    return (
        <ListGroup className="cart-container">
            {cart.map((cartItem, index) => {
                return (
                    <CartItemWrapper
                        key={index}
                        productImage={
                            cartItem.fields.productImage.fields.file.url
                        }
                        productName={cartItem.fields.productName}
                        quantity={cartItem.quantity}
                        sumPrice={cartItem.quantity * cartItem.fields.price}
                        productId={cartItem.sys.id}
                        handleQtyChange={handleQtyChange}
                        uid={cartItem.fields.url}
                    />
                );
            })}
        </ListGroup>
    );
}
