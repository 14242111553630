import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { contactSubmission } from "../../actions/ContactSubmission";

export default function ContactForm({ setShowContact }) {
    const submitForm = async (form) => {
        const formData = new FormData(form);
        const formProps = Object.fromEntries(formData);
        contactSubmission(formProps).then(() => {
            setShowContact(false);
        });
    };
    return (
        <Form
            onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
                submitForm(event.target);
            }}
        >
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Név</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Név"
                            name="name"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>E-mail cím</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            placeholder="E-mail cím"
                            name="email"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Üzenet</Form.Label>
                        <Form.Control
                            required
                            rows={3}
                            as="textarea"
                            name="message"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Check required name="gdrpConsent" type="checkbox">
                        <Form.Check.Input type="checkbox" />
                        <Form.Check.Label>
                            Az{" "}
                            <Link to="/adatkezelesi-tajekoztato">
                                adatkezelési tájékoztatóban
                            </Link>{" "}
                            foglaltakat elfogadom
                        </Form.Check.Label>
                    </Form.Check>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end">
                    <Button type="submit">Küldés</Button>
                </Col>
            </Row>
        </Form>
    );
}
