import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ButtonLink from "../components/reusable/ButtonLink";

export default function ConfirmationScene() {
    return (
        <Container className="w-75 confirmation-container">
            <Row>
                <Col>
                    <h3 className="text-center">Köszönjük a vásárlást!</h3>
                    <p className="mt-3 text-center">
                        Megrendelésed feldolgozását megkezdtük, hamarosan
                        felvesszük veled a kapcsolatot e-mailben!
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end mt-3">
                    <ButtonLink to="/">Vissza a kezdőlapra</ButtonLink>
                </Col>
            </Row>
        </Container>
    );
}
