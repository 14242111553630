import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "../reusable/ContactForm";

export default function Contacts() {
    return (
        <Container className="p-5 contact-container" fluid>
            <Row>
                <Col>
                    <Container className="w-75 contact-form-container">
                        <Row>
                            <Col>
                                <ContactForm />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}
