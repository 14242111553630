import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function AboutWrapper() {
    return (
        <Container fluid>
            {/* <Row className='justify-content-center py-3'>
                <Col md={6}>
                    <div>
                        <h2 className='text-center'>Rólunk</h2>
                        <p>
                            A Ho-Pe egy 1775 óta működő családi vállakozás, ami
                            Lorem ipsum dolor sit amet consectetur adisciping
                            elit.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-center py-3'>
                <Col md={6}>
                    <div>
                        <h2 className='text-center'>
                            Álmodd meg, mi elkészítjük!
                        </h2>
                        <p>
                            Tetszik az egyik ékszer, de szeretnéd egy szeretted
                            nevét beleírni? Tetszik az egyik minta, de nem
                            találsz belőle a honlapon párnát?
                            <br />
                            Bármilyen egyedi kéréseddel fordulj hozzánk
                            bizalommal e-mailben, vagy Facebookon!
                        </p>
                    </div>
                </Col>
            </Row> */}
        </Container>
    );
}
