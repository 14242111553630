import CartList from "../components/CartList";
import React, { useContext } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { ProductContext } from "../context";
import { useHistory } from "react-router-dom";

export default function CartListWrapper({ nextPage }) {
    const { cart } = useContext(ProductContext);
    const history = useHistory();
    return (
        <Container className="cart-list-container">
            <Row className="justify-content-center">
                <Col md={8}>
                    <Row>
                        <Col>
                            <h3 className="text-center pb-3">
                                A kosár tartalma
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            {cart.length > 0 ? (
                                <CartList />
                            ) : (
                                "Kosarad jelenleg üres"
                            )}
                        </Col>
                    </Row>
                    <Row className="justify-content-end">
                        <Col>
                            <div className="d-flex justify-content-end">
                                <Button
                                    disabled={cart.length === 0}
                                    onClick={() => {
                                        history.push(nextPage);
                                    }}
                                >
                                    Tovább
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
