import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Container, Button, Image } from "react-bootstrap";
import SimilarProducts from "../SimilarProducts";
import LoadingIcon from "../reusable/LoadingIcon";
import { ProductContext } from "../../context";
import { Link, useHistory } from "react-router-dom";

export default function ProductPage({ match }) {
    const { addToCart, client, handleCartShow } = useContext(ProductContext);
    const [content, setContent] = useState(null);
    const uid = match.params.uid;
    const history = useHistory();

    useEffect(() => {
        async function queryProduct() {
            let response = await client.getEntries({
                content_type: "product",
                "fields.url": uid,
            });
            if (response.items.length > 0) {
                setContent(response.items[0]);
            } else {
                history.push("/404");
            }
        }
        queryProduct();
        window.scrollTo(0, 0);
    }, [uid]);

    function cartHandler() {
        addToCart(content, 1);
    }

    return (
        <Container className="py-5">
            {!content ? (
                <LoadingIcon />
            ) : (
                <Container>
                    <Row className="product-details-container d-flex justify-content-center">
                        <Col
                            className="product-left text-center"
                            md={5}
                            xs={12}
                        >
                            <Image
                                className="prodimage"
                                src={
                                    "https:" +
                                    content.fields.productImage.fields.file
                                        .url +
                                    "?w=618&h=410"
                                }
                                alt={content.fields.productName}
                                fluid
                            />
                        </Col>
                        <Col className="product-right" md={5} xs={12}>
                            <div className="prodname-container">
                                <h1>{content.fields.productName}</h1>
                            </div>
                            <div className="property-list-container">
                                <div className="mb-3 price-container">
                                    <span>
                                        <strong>
                                            {content.fields.price} Ft
                                        </strong>
                                    </span>
                                </div>
                                <div className="d-grid mb-3">
                                    <Button
                                        type="submit"
                                        onClick={() => {
                                            cartHandler();
                                            handleCartShow();
                                        }}
                                        size="lg"
                                    >
                                        <strong>Kosárba</strong>
                                    </Button>
                                </div>
                                <div className="description-container">
                                    <p>{content.fields.description}</p>
                                </div>
                                <div className="property-container">
                                    <span>
                                        <b className="property">Kategória:</b>
                                        <Link
                                            to={
                                                "/vasarlas?category=" +
                                                content.fields.category
                                            }
                                        >
                                            <span className="value">
                                                {content.fields.category}
                                            </span>
                                        </Link>
                                    </span>
                                </div>
                                <div className="property-container">
                                    <span>
                                        <b className="property">Típus:</b>
                                        <Link
                                            to={
                                                "/vasarlas?type=" +
                                                content.fields.type
                                            }
                                        >
                                            <span className="value">
                                                {content.fields.type}
                                            </span>
                                        </Link>
                                    </span>
                                </div>
                                <div className="property-container">
                                    <span>
                                        <b className="property">Anyag:</b>
                                        <Link
                                            to={
                                                "/vasarlas?material=" +
                                                content.fields.material
                                            }
                                        >
                                            <span className="value">
                                                {content.fields.material}
                                            </span>
                                        </Link>
                                    </span>
                                </div>
                                <div className="property-container">
                                    {!!content.fields.pattern && (
                                    <span>
                                    <b className="property">Minta:</b>
                                    <Link
                                        to={
                                            "/vasarlas?pattern=" +
                                            content.fields.pattern.fields
                                                .value
                                        }
                                    >
                                        <span className="value">
                                            {
                                                content.fields.pattern
                                                    .fields.name
                                            }
                                        </span>
                                    </Link>
                                </span>                                    
                                    )}
                                </div>
                                <div className="property-container">
                                    <span>
                                        <b className="property">Szín:</b>
                                        <Link
                                            to={
                                                "/vasarlas?color=" +
                                                content.fields.color.fields
                                                    .value
                                            }
                                        >
                                            <span className="value">
                                                {
                                                    content.fields.color.fields
                                                        .name
                                                }
                                            </span>
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <SimilarProducts props={content} />
                    </Row>
                </Container>
            )}
        </Container>
    );
}
