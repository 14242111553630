import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import CheckoutForm from "../../form components/CheckoutForm";
import OrderSummary from "../../form components/OrderSummary";
import CartListWrapper from "../../form components/CartListWrapper";
import DeliveryInfo from "../../form components/DeliveryInfo";
import ConfirmationScene from "../../form components/ConfirmationScene";
import { Row, Container, Col } from "react-bootstrap";

export default function CheckoutPage() {
    const history = useHistory();
    useEffect(() => {
        history.push("/fizetes");
    }, []);
    return (
        <>
            <Container className="p-5 purchase-container" fluid>
                <Row>
                    <Col>
                        <Switch>
                            <Route exact path="/fizetes">
                                <CartListWrapper nextPage="/fizetes/szallitas" />
                            </Route>
                            <Route exact path="/fizetes/szallitas">
                                <DeliveryInfo nextPage="/fizetes/informaciok" />
                            </Route>
                            <Route exact path="/fizetes/informaciok">
                                <CheckoutForm nextPage="vasarlas-osszesito" />
                            </Route>
                            <Route exact path="/fizetes/vasarlas-osszesito">
                                <OrderSummary nextPage="megerosites" />
                            </Route>
                            <Route exact path="/fizetes/megerosites">
                                <ConfirmationScene nextPage="../" />
                            </Route>
                            <Redirect from="/fizetes/*" to="/404" />
                        </Switch>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
