import React from "react";
import { Row, Col, Image } from "react-bootstrap";

export default function ProductCard({ img, title, price }) {
    return (
        <div className="card-wrapper my-3 ">
            <Row className="g-0">
                <Col xs={12}>
                    <div className="prod-img-wrapper">
                        <Image className="prod-img" src={img} />
                    </div>
                </Col>
                <Col xs={12}>
                    <Row className="text-center px-2 pt-2">
                        <Col>
                            <p>{title}</p>
                        </Col>
                    </Row>
                    <Row className="text-center px-2 pt-2">
                        <Col>
                            <p>{price} Ft</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
