import React from "react";
import { Container } from "react-bootstrap";

const css = `
p.p1 {margin: 0.3px 0.0px 0.0px 0.0px; text-align: center; font: 16.0px 'Times New Roman'}
p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'; min-height: 15.0px}
p.p3 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'}
p.p4 {margin: 0.0px 0.0px 0.0px 36.0px; text-align: justify; font: 16.0px 'Times New Roman'; min-height: 15.0px}
p.p5 {margin: 0.0px 0.0px 0.0px 18.0px; text-align: justify; font: 16.0px 'Times New Roman'; min-height: 15.0px}
p.p6 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'; color: #00000a}
p.p7 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px 'Times New Roman'; color: #00000a}
p.p8 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'; color: #00000a; min-height: 15.0px}
p.p9 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: center; font: 16.0px 'Times New Roman'}
p.p10 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: center; font: 16.0px 'Times New Roman'; min-height: 15.0px}
li.li3 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'}
li.li6 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'; color: #00000a}
span.s1 {color: #000000}
table.t1 {border-collapse: collapse}
td.td1 {border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #bfbfbf #bfbfbf #bfbfbf #bfbfbf; padding: 0.0px 5.0px 0.0px 5.0px}
ul.ul1 {list-style-type: disc}
`;

export default function Data() {
    return (
        <Container className="py-5">
            <style>{css}</style>
            <p className="p1">
                <b>ADATKEZELÉSI TÁJÉKOZTATÓ</b>
            </p>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Az adatkezelési tájékoztató célja</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A Ho-Pe 2002 Oktatási és Szolgáltató Betéti Társaság (a
                továbbiakban: szolgáltató, adatkezelő) mint adatkezelő, magára
                nézve kötelezőnek ismeri el jelen jogi közlemény tartalmát.
                Kötelezettséget vállal arra, hogy tevékenységével kapcsolatos
                minden adatkezelés megfelel a jelen szabályzatban és a hatályos
                nemzeti jogszabályokban, valamint az Európai Unió jogi
                aktusaiban meghatározott elvárásoknak.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az Adatkezelő fenntartja magának a jogot jelen tájékoztató
                bármikori megváltoztatására. Természetesen az esetleges
                változásokról kellő időben értesítést nyújt.
            </p>
            <p className="p3">
                Amennyiben kérdése lenne jelen közleményünkhöz kapcsolódóan,
                kérjük, írja meg nekünk.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az Adatkezelő elkötelezett Vásárlói személyes adatainak
                védelmében, kiemelten fontosnak tartja Vásárlói információs
                önrendelkezési jogának tiszteletben tartását. Az Adatkezelő a
                személyes adatokat bizalmasan kezeli, és megtesz minden olyan
                biztonsági, technikai és szervezési intézkedést, mely az adatok
                biztonságát garantálja.
            </p>
            <p className="p3">
                Az Adatkezelő az alábbiakban ismerteti adatkezelési gyakorlatát.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Az adatkezelő adatai</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Amennyiben megkeresné Társaságunkat,{" "}
                <a href="mailto:hopekezmuves18@gmail.com">
                    hopekezmuves18@gmail.com
                </a>
                e-mail-címen és +36309668651 telefonszámon elérhetőségeken
                léphet kapcsolatba az adatkezelővel.
            </p>
            <p className="p3">
                Az Adatkezelő minden hozzá beérkezett e-mailt a személyes
                adatokkal együtt az adatközléstől számított legfeljebb 1 év
                elteltével töröl.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Név: Ho-Pe 2002 Oktatási és Szolgáltató Betéti Társaság
            </p>
            <p className="p3">Székhely: 1035 Budapest, Szél utca 17. 7. em. 38.</p>
            <p className="p3">
                Levelezési cím: 1035 Budapest, Szél utca 17. 7. em. 38.
                <span className="Apple-converted-space"></span>
            </p>
            <p className="p3">
                Nyilvántartásba vevő hatóság: Fővárosi Törvényszék Cégbírósága
            </p>
            <p className="p3">
                Cégjegyzékszám: 01-06-749191{" "}
                <span className="Apple-converted-space"></span>
            </p>
            <p className="p3">Adószám: 21354452-1-41</p>
            <p className="p3">Vezető tisztségviselő: Hogyor Zsuzsanna</p>
            <p className="p3">Telefonszám: +36309668651</p>
            <p className="p3">
                E-mail:{" "}
                <a href="mailto:hopekezmuves18@gmail.com">
                    hopekezmuves18@gmail.com
                </a>
            </p>
            <p className="p3">
                Honlap: <a href="hopekezmuves.hu">hopekezmuves.hu</a>
            </p>
            <p className="p3">Bankszámlaszám: 65700127-10121681 Takarékbank</p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>A kezelt személyes adatok köre</b>
                </li>
            </ul>
            <p className="p5">
                <b></b>
                <br />
            </p>
            <p className="p3">A Vásárlás során megadandó személyes adatok</p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">A vásárlás során elkért személyes adatok:</p>
            <ul className="ul1">
                <li className="li3">Név (vezetéknév, keresztnév)</li>
                <li className="li3">Email cím</li>
                <li className="li3">Telefonszám</li>
                <li className="li3">
                    Számlázási cím (település, irányítószám, közterület neve,
                    közterület jellege, házszám, épület, lépcsőház, emelet,
                    ajtó)<span className="Apple-converted-space"></span>
                </li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Technikai adatok</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Az Adatkezelő a személyes adatok kezeléséhez a szolgáltatás
                nyújtása során alkalmazott informatikai eszközöket úgy választja
                meg és üzemelteti, hogy a kezelt adat:
            </p>
            <ul className="ul1">
                <li className="li3">
                    az arra feljogosítottak számára hozzáférhető (rendelkezésre
                    állás); <span className="Apple-converted-space"></span>
                </li>
                <li className="li3">
                    hitelessége és hitelesítése biztosított (adatkezelés
                    hitelessége);
                </li>
                <li className="li3">
                    változatlansága igazolható (adatintegritás);
                </li>
                <li className="li3">
                    a jogosulatlan hozzáférés ellen védett (adat bizalmassága)
                    legyen.
                </li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az Adatkezelő az adatokat megfelelő intézkedésekkel védi a
                jogosulatlan hozzáférés, megváltoztatás, továbbítás,
                nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a
                véletlen megsemmisülés ellen.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az Adatkezelő olyan műszaki, szervezési és szervezeti
                intézkedésekkel gondoskodik az adatkezelés biztonságának
                védelméről, amely az adatkezeléssel kapcsolatban jelentkező
                kockázatoknak megfelelő védelmi szintet nyújt.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">Az Adatkezelő az adatkezelés során megőrzi</p>
            <ul className="ul1">
                <li className="li3">
                    a titkosságot: megvédi az információt, hogy csak az
                    férhessen hozzá, aki erre jogosult;
                </li>
                <li className="li3">
                    a sértetlenséget: megvédi az információnak és a feldolgozás
                    módszerének a pontosságát és teljességét;
                </li>
                <li className="li3">
                    a rendelkezésre állást: gondoskodik arról, hogy amikor a
                    jogosult használónak szüksége van rá, valóban hozzá tudjon
                    férni a kívánt információhoz, és rendelkezésre álljanak az
                    ezzel kapcsolatos eszközök.
                </li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Cookie-k (Sütik)</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p3">A sütik feladata</p>
            <ul className="ul1">
                <li className="li3">
                    információkat gyűjtenek a látogatókról és eszközeikről;
                </li>
                <li className="li3">
                    megjegyzik a látogatók egyéni beállításait, amelyek
                    felhasználásra kerül(het)nek pl. online tranzakciók
                    igénybevételekor, így nem kell újra begépelni őket;
                </li>
                <li className="li3">megkönnyítik a weboldal használatát;</li>
                <li className="li3">minőségi felhasználói élményt biztosítanak.</li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A testre szabott kiszolgálás érdekében a felhasználó
                számítógépén kis adatcsomagot, ún. sütit (cookie) helyez el és a
                későbbi látogatás során olvas vissza. Ha a böngésző visszaküld
                egy korábban elmentett sütit, a sütit kezelő szolgáltatónak
                lehetősége van összekapcsolni a felhasználó aktuális látogatását
                a korábbiakkal, de kizárólag a saját tartalma tekintetében.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <b>Kosár tartalma (cart)</b>
            </p>
            <p className="p3">
                Az adatkezelés időtartama: Az információ az utolsó interakciótól
                számított 24 óra utáni következő oldalbetöltésig.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <b>Egyéb tájékoztatók</b>
            </p>
            <p className="p3">
                Google általános cookie tájékoztató:
                <a href="https://www.google.com/policies/technologies/types/">
                    https://www.google.com/policies/technologies/types/
                </a>
            </p>
            <p className="p3">
                Google Analitycs tájékoztató:
                <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=hu">
                    https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=hu
                </a>
            </p>
            <p className="p3">
                Facebook tájékoztató:
                <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">
                    https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
                </a>
            </p>
            <p className="p8">
                <br />
            </p>
            <p className="p8">
                <br />
            </p>
            <p className="p3">
                <b>A cookie-k jogszabályi háttere és jogalapja:</b>
            </p>
            <p className="p3">
                Az adatkezelés jogalapja a Rendelet 6. cikk (1) bekezdés a)
                pontja alapján az Ön hozzájárulása.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Az adatkezelés célja, módja és jogalapja</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">Általános adatkezelési irányelvek</p>
            <p className="p3">
                Az Adatkezelő tevékenységének adatkezelései önkéntes
                hozzájáruláson, illetve törvényi felhatalmazáson alapulnak. Az
                önkéntes hozzájáruláson alapuló adatkezelések esetében az
                érintettek e hozzájárulásukat az adatkezelés bármely szakában
                visszavonhatják.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Bizonyos esetekben a megadott adatok egy körének kezelését,
                tárolását, továbbítását jogszabályok teszik kötelezővé, melyről
                külön értesítjük ügyfeleinket.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Felhívjuk az Adatkezelő részére adatközlők figyelmét, hogy
                amennyiben nem saját személyes adataikat adják meg, az adatközlő
                kötelessége az érintett hozzájárulásának beszerzése.
            </p>
            <p className="p3">
                Adatkezelési alapelvei összhangban vannak az adatvédelemmel
                kapcsolatos hatályos jogszabályokkal, így különösen az
                alábbiakkal:
            </p>
            <ul className="ul1">
                <li className="li3">
                    2011. évi CXII. törvény - az információs önrendelkezési
                    jogról és az információ-szabadságról (Infotv.);
                </li>
                <li className="li3">
                    Az Európai Parlament és a Tanács (EU) 2016/679 rendelete
                    (2016. április 27.) – a természetes személyeknek a személyes
                    adatok kezelése tekintetében történő védelméről és az ilyen
                    adatok szabad áramlásáról, valamint a 95/46/EK rendelet
                    hatályon kívül helyezéséről (általános adatvédelmi rendelet,
                    GDPR);
                </li>
                <li className="li3">
                    2013. évi V. törvény – a Polgári Törvénykönyvről (Ptk.);
                    <span className="Apple-converted-space"></span>
                </li>
                <li className="li3">
                    2000. évi C. törvény – a számvitelről (Számv. tv.);
                </li>
                <li className="li3">
                    2017. évi LIII. törvény – a pénzmosás és terrorizmus
                    finanszírozása megelőzéséről és megakadályozásáról (Pmt.);
                </li>
                <li className="li3">
                    2013. évi CCXXXVII. törvény – a hitelintézetekről és a
                    pénzügyi vállalkozásokról (Hpt.).
                </li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Az adatok fizikai tárolási helyei</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Személyes adatai (vagyis azok az adatok, amelyek az Ön
                személyével kapcsolatba hozhatók) a következő módon kerülhetnek
                a kezelésünkbe: egyfelől az internetes kapcsolat fenntartásával
                összefüggésben az Ön által használt számítógéppel,
                böngészőprogrammal, internetes címmel, a látogatott oldalakkal
                kapcsolatos technikai adatok automatikusan képződnek
                számítógépes rendszerünkben, másfelől Ön is megadhatja nevét,
                elérhetőségét vagy más adatait, ha a honlap használata során
                személyes kapcsolatba kíván lépni velünk.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>
                        Adattovábbítás, adatfeldogozás, az adatokat megismerők
                        köre
                    </b>
                </li>
            </ul>
            <p className="p2">
                <span className="Apple-converted-space"></span>
            </p>
            <p className="p3">
                Társaságunk az adatok kezelése során – ügyfeleink színvonalas
                kiszolgálása érdekében – az alábbi adatfeldolgozókat veszi
                igénybe:
            </p>
            <p className="p2">
                <br />
            </p>
            <table cellSpacing="0" cellPadding="0" className="t1">
                <tbody>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p9">Név</p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p9">Cím</p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p9">Tevékenység</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p9">Alphabet Inc.</p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p9">
                                1600 Amphitheater Parkway Mountain View, CA
                                94043 USA
                            </p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p9">tárhely szolgáltató</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p9">Magyar Posta Zrt.</p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p9">1138 Budapest, Dunavirág utca 2-6.</p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p9">futárszolgálat</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p9">FoxPost Zrt.</p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p9">3300 Eger, Pacsirta utca 35/A</p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p9">futárszolgálat</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az Adatfeldolgozó az Adatkezelővel kötött szerződés alapján
                közreműködik a megrendelések nyilvántartásában. Ennek során az
                Adatfeldolgozó az érintett nevét, címét, telefonszámát, a
                rendelések számát és időpontját a polgári jogi elévülési időn
                belül kezeli.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Érintett jogai és jogérvényesítési lehetőségei</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Az adatkezelés időtartamán belül Önt a Rendelet előírásai
                szerint az alábbi jogok illetik meg:
            </p>
            <ul className="ul1">
                <li className="li3">a hozzájárulás visszavonásának joga</li>
                <li className="li3">
                    személyes adatokhoz és az adatkezeléssel kapcsolatos
                    információkhoz való hozzáférés
                </li>
                <li className="li3">helyesbítéshez való jog</li>
                <li className="li3">adatkezelés korlátozása,</li>
                <li className="li3">törléshez való jog</li>
                <li className="li3">tiltakozáshoz való jog</li>
                <li className="li3">hordozhatósághoz való jog.</li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Amennyiben Ön jogaival élni kíván, az az Ön azonosításával jár
                együtt, valamint az Adatkezelőnek Önnel szükségszeren
                kommunikálnia kell. Ezért az azonosítás érdekében személyes
                adatok megadására lesz szükség (de az azonosítás csak olyan
                adaton alapulhat, amelyet Adatkezelő egyébként is kezel Önről),
                valamint az Adatkezelő email fiókjában elérhetőek lesz az Ön
                adatkezeléssel kapcsolatos panasza a jelen tájékoztatóban, a
                panaszokkal kapcsolatban megjelölt időtartamon belül. Amennyiben
                Ön vásárlónk volt és panaszügyintézés, vagy garanciális
                ügyintézés érdekében szeretné magát azonosítani, az
                azonosításhoz kérjük adja meg rendelési azonosítóját is. Ennek
                felhasználásával Önt, mint vásárlót is be tudjuk azonosítani.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az adatkezeléssel kapcsolatos panaszokat legkésőbb 30 napon
                belül válaszolja meg Adatkezelő.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Tájékoztatáshoz való jog</b>
            </p>
            <p className="p3">
                Az Adatkezelő megfelelő intézkedéseket hoz annak érdekében, hogy
                az érintettek részére a személyes adatok kezelésére vonatkozó, a
                GDPR 13. és a 14. cikkben említett valamennyi információt és a
                15–22. és 34. cikk szerinti minden egyes tájékoztatást tömör,
                átlátható, érthető és könnyen hozzáférhető formában, világosan
                és közérthetően megfogalmazva nyújtsa.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Az érintett hozzáféréshez való joga</b>
            </p>
            <p className="p3">
                Ön jogosult arra, hogy az Adatkezelőtől visszajelzést kapjon
                arra vonatkozóan, hogy személyes adatainak kezelése folyamatban
                van-e, és ha adatkezelés folyamatban van, jogosult arra, hogy:
            </p>
            <ul className="ul1">
                <li className="li3">
                    a kezelt személyes adatokhoz hozzáférést kapjon és
                </li>
                <li className="li3">
                    a következő információkról az Adatkezelő tájékoztassa:
                </li>
                <li className="li3">az adatkezelés céljai;</li>
                <li className="li3">
                    az Önről kezelt személyes adatok kategóriái;
                </li>
                <li className="li3">
                    információ azon címzettekről vagy címzettek kategóriáiról,
                    akikkel, illetve amelyekkel a személyes adatokat Adatkezelő
                    közölte vagy közölni fogja;
                </li>
                <li className="li3">
                    a személyes adatok tárolásának tervezett időtartama, vagy ha
                    ez nem lehetséges, ezen időtartam meghatározásának
                    szempontjai;
                </li>
                <li className="li3">
                    az Ön azon joga, hogy kérelmezheti az Adatkezelőtől az Önre
                    vonatkozó személyes adatok helyesbítését, törlését vagy
                    kezelésének korlátozását, és jogos érdeken alapuló
                    adatkezelés esetén tiltakozhat az ilyen személyes adatok
                    kezelése ellen;
                </li>
                <li className="li3">
                    a felügyeleti hatósághoz címzett panasz benyújtásának joga;
                </li>
                <li className="li3">
                    ha az adatokat nem Öntől gyűjtötték be, a forrásukra
                    vonatkozó minden elérhető információ;
                </li>
                <li className="li3">
                    az automatizált döntéshozatal tényéről (ha alkalmazott ilyen
                    eljárás), ideértve a profilalkotást is, valamint legalább
                    ezekben az esetekben az alkalmazott logikára és arra
                    vonatkozóan érthető információkat, hogy az ilyen adatkezelés
                    milyen jelentőséggel, és Önre nézve milyen várható
                    következményekkel bír.
                </li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A jog gyakorlásának célja az adatkezelés jogszerűségének
                megállapítására és ellenőrzésére irányulhat, ezért többszöri
                tájékoztatás kérés esetén Adatkezelő méltányos költségtérítést
                számolhat fel a tájékoztatás teljesítéséért cserébe.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A személyes adatokhoz való hozzáférést Adatkezelő úgy
                biztosítja, hogy az Ön azonosítását követően emailben juttatja
                el Önhöz a kezelt személyes adatokat és az információkat.
                Amennyiben regisztrációval rendelkezik, akkor a hozzáférést úgy
                biztosítjuk, hogy a felhasználói fiókjába belépve tudja az Önről
                kezelt személyes adatokat megtekinteni és ellenőrizni.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Kérjük, hogy kérelmében jelölje meg, hogy a személyes adatokhoz
                kér hozzáférést, vagy az adatkezeléssel kapcsolatos
                információkat kéri.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Helyesbítés joga</b>
            </p>
            <p className="p3">
                Ön jogosult arra, hogy kérésére Adatkezelő késedelem nélkül
                helyesbítse az Önre vonatkozó pontatlan személyes adatokat.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Törléshez való jog</b>
            </p>
            <p className="p3">
                Az érintett az alábbi indokok valamelyikének fennállása esetén
                jogosult arra, hogy kérésére az Adatkezelő indokolatlan
                késedelem nélkül törölje a rá vonatkozó személyes adatokat:
            </p>
            <ul className="ul1">
                <li className="li3">
                    személyes adatokra már nincs szükség abból a célból,
                    amelyből azokat gyűjtötték vagy más módon kezelték;
                </li>
                <li className="li3">
                    az érintett visszavonja az adatkezelés alapját képező
                    hozzájárulását, és az adatkezelésnek nincs más jogalapja;
                </li>
                <li className="li3">
                    az érintett tiltakozik az adatkezelés ellen, és nincs
                    elsőbbséget élvező jogszerű ok az adatkezelésre;
                </li>
                <li className="li3">a személyes adatokat jogellenesen kezelték;</li>
                <li className="li3">
                    a személyes adatokat az adatkezelőre alkalmazandó uniós vagy
                    tagállami jogban előírt jogi kötelezettség teljesítéséhez
                    törölni kell;
                </li>
                <li className="li3">
                    a személyes adatok gyűjtésére információs társadalommal
                    összefüggő szolgáltatások kínálásával kapcsolatosan került
                    sor.
                </li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az adatok törlése nem kezdeményezhető, ha az adatkezelés
                szükséges: a véleménynyilvánítás szabadságához és a
                tájékozódáshoz való jog gyakorlása céljából; a személyes adatok
                kezelését előíró, az adatkezelőre alkalmazandó uniós vagy
                tagállami jog szerinti kötelezettség teljesítése, illetve
                közérdekből vagy az adatkezelőre ruházott közhatalmi jogosítvány
                gyakorlása keretében végzett feladat végrehajtása céljából; a
                népegészség-ügy területét érintő, vagy archiválási, tudományos
                és történelmi kutatási célból vagy statisztikai célból, közérdek
                alapján; vagy jogi igények előterjesztéséhez, érvényesítéséhez,
                illetve védelméhez.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Az adatkezelés korlátozásához való jog</b>
            </p>
            <p className="p3">
                Az érintett kérésére az Adatkezelő korlátozza az adatkezelést,
                ha az alábbi feltételek valamelyike teljesül:
            </p>
            <ul className="ul1">
                <li className="li3">
                    az érintett vitatja a személyes adatok pontosságát, ez
                    esetben a korlátozás arra az időtartamra
                </li>
                <li className="li3">
                    vonatkozik, amely lehetővé teszi, a személyes adatok
                    pontosságának ellenőrzését;
                </li>
                <li className="li3">
                    az adatkezelés jogellenes, és az érintett ellenzi az adatok
                    törlését, és ehelyett kéri azok felhasználásának
                    korlátozását;
                </li>
                <li className="li3">
                    az adatkezelőnek már nincs szüksége a személyes adatokra
                    adatkezelés céljából, de az érintett igényli azokat jogi
                    igények előterjesztéséhez, érvényesítéséhez vagy védelméhez;
                    vagy
                </li>
                <li className="li3">
                    az érintett tiltakozott az adatkezelés ellen; ez esetben a
                    korlátozás arra az időtartamra vonatkozik, amíg
                    megállapításra nem kerül, hogy az adatkezelő jogos indokai
                    elsőbbséget élveznek-e az érintett jogos indokaival szemben.
                </li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Ha az adatkezelés korlátozás alá esik, a személyes adatokat a
                tárolás kivételével csak az érintett hozzájárulásával, vagy jogi
                igények előterjesztéséhez, érvényesítéséhez vagy védelméhez,
                vagy más természetes vagy jogi személy jogainak védelme
                érdekében, vagy az Unió, illetve valamely tagállam fontos
                közérdekéből lehet kezelni.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Adathordozáshoz való jog</b>
            </p>
            <p className="p3">
                Amennyiben az adatkezelés automatizált módon valósul meg, vagy
                ha az adatkezelés az Ön önkéntes hozzájárulásán alapul, Önnek
                joga van arra, hogy kérje Adatkezelőtől, hogy az Ön által az
                Adatkezelő részére megadott adatokat megkapja, amit Adatkezelő
                xml, JSON, vagy csv formátumban bocsát az Ön rendelkezésére, ha
                ez technikailag megvalósítható, akkor kérheti, hogy az
                Adatkezelő az adatokat ebben a formában más adatkezelő számára
                továbbítsa.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Tiltakozás joga</b>
            </p>
            <p className="p3">
                Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos
                okokból bármikor tiltakozzon személyes adatainak közérdekű vagy
                az adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának
                keretében végzett feladat végrehajtásához szükséges adatkezelés,
                vagy az adatkezelő vagy egy harmadik fél jogos érdekeinek
                érvényesítéséhez szükséges kezelése ellen, ideértve az említett
                rendelkezéseken alapuló profilalkotást is. Tiltakozás esetén az
                adatkezelő a személyes adatokat nem kezelheti tovább, kivéve, ha
                azt olyan kényszerítő erejű jogos okok indokolják, amelyek
                elsőbbséget élveznek az érintett érdekeivel, jogaival és
                szabadságaival szemben, vagy amelyek jogi igények
                előterjesztéséhez, érvényesítéséhez vagy védelméhez
                kapcsolódnak.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Automatizált döntéshozatal egyedi ügyekben, beleértve a
                profilalkotást
            </p>
            <p className="p3">
                Az érintett jogosult arra, hogy ne terjedjen ki rá az olyan,
                kizárólag automatizált adatkezelésen – ideértve a profilalkotást
                is – alapuló döntés hatálya, amely rá nézve joghatással járna
                vagy őt hasonlóképpen jelentős mértékben érintené.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Visszavonás joga</b>
            </p>
            <p className="p3">
                Az érintett jogosult arra, hogy hozzájárulását bármikor
                visszavonja.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Bírósághoz fordulás joga</b>
            </p>
            <p className="p3">
                Az érintett a jogainak megsértése esetén az adatkezelő ellen
                bírósághoz fordulhat. A bíróság az ügyben soron kívül jár el.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Adatvédelmi hatósági eljárás</b>
            </p>
            <p className="p3">
                Panasszal a Nemzeti Adatvédelmi és Információszabadság
                Hatóságnál lehet élni:
                <span className="Apple-converted-space"></span>
            </p>
            <ul className="ul1">
                <li className="li3">
                    Név: Nemzeti Adatvédelmi és Információszabadság Hatóság
                </li>
                <li className="li3">
                    Székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/C.
                    Levelezési cím: 1530 Budapest, Pf.: 5.
                </li>
                <li className="li3">Telefon: 0613911400</li>
                <li className="li3">Fax: 0613911410</li>
                <li className="li6">
                    <span className="s1">E-mail: </span>ugyfelszolgalat@naih.hu
                    <span className="s1"> Honlap: </span>http://www.naih.hu
                </li>
            </ul>
            <p className="p8">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Egyéb rendelkezések</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p3">
                E tájékoztatóban fel nem sorolt adatkezelésekről az adat
                felvételekor adunk tájékoztatást.
            </p>
            <p className="p3">
                Tájékoztatjuk ügyfeleinket, hogy a bíróság, az ügyész, a nyomozó
                hatóság, a szabálysértési hatóság, a közigazgatási hatóság, a
                Nemzeti Adatvédelmi és Információszabadság Hatóság, a Magyar
                Nemzeti Bank, illetőleg jogszabály felhatalmazása alapján más
                szervek tájékoztatás adása, adatok közlése, átadása, illetőleg
                iratok rendelkezésre bocsátása végett megkereshetik az
                adatkezelőt.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az Adatkezelő a hatóságok részére – amennyiben a hatóság a
                pontos célt és az adatok körét megjelölte – személyes adatot
                csak annyit és olyan mértékben ad ki, amely a megkeresés
                céljának megvalósításához elengedhetetlenül szükséges.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A jelen dokumentumban nem szabályozott kérdésekben az Európai
                Unió 2016/679 számú Általános Adatvédelmi Rendelete (a
                továbbiakban: Rendelet. GDPR) és a 2011. évi CXII. tv. (a
                továbbiakban: Infotv.) vonatkozó rendelkezései az irányadók.
            </p>
        </Container>
    );
}
