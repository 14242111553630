import React, { useContext, useState } from "react";
import { Col, Row, Form, Button, Container } from "react-bootstrap";
import { ProductContext } from "../context";
import { Link, useHistory } from "react-router-dom";
import CHECKOUT_CONSTANTS from "./constants/checkoutConstants";

export default function CheckoutForm({ nextPage }) {
    const { handleInfoChange, paymentInfo } = useContext(ProductContext);
    const history = useHistory();

    const [isCompany, setIsCompany] = useState(false);
    const [isSameInfo, setIsSameInfo] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(nextPage);
        return true;
    };

    const onInputChange = (e) => {
        if (e.target.type !== "checkbox") {
            if (e.target.value !== undefined) {
                handleInfoChange(e.target.name, e.target.value);
            } else {
                handleInfoChange(e.target.name, "");
            }
        } else {
            handleInfoChange(e.target.name, e.target.checked);
        }
    };

    const setSameInfos = () => {
        CHECKOUT_CONSTANTS.DELIVERY_INFO.forEach((info) => {
            paymentInfo[info.name] &&
                handleInfoChange(`billing${info.name}`, paymentInfo[info.name]);
        });
    };

    return (
        <Container className="checkout-container">
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <h3 className="pb-3 text-center">
                                    Vásárló adatai
                                </h3>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={6}>
                                <Form.Group className="form-group">
                                    <Form.Check
                                        type="checkbox"
                                        id="companyTaxId"
                                    >
                                        <Form.Check.Input
                                            type="checkbox"
                                            name="isCompany"
                                            onClick={(e) =>
                                                setIsCompany(e.target.checked)
                                            }
                                        />
                                        <Form.Check.Label>
                                            Nem magánszemély nevében vásárolok
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                            </Col>
                            {isCompany && (
                                <Col xs={12} md={6}>
                                    <Form.Group className="form-group required">
                                        <Form.Label>Adószám</Form.Label>
                                        <Form.Control
                                            required
                                            name="taxId"
                                            type="text"
                                            placeholder="Adószám"
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                        <h4 className="mb-3">Személyes adatok</h4>
                        <Row className="mb-3">
                            {CHECKOUT_CONSTANTS.PERSONAL_INFO.map(
                                (info, idx) => (
                                    <Col
                                        xs={12}
                                        md={info.fullWidth ? 12 : 6}
                                        key={idx}
                                        className="mb-3"
                                    >
                                        <Form.Group
                                            className={`form-group ${
                                                info.isRequired && "required"
                                            }`}
                                        >
                                            <Form.Label>
                                                {info.label}
                                            </Form.Label>
                                            <Form.Control
                                                required={info.isRequired}
                                                name={info.name}
                                                type={info.type}
                                                placeholder={info.placeholder}
                                                onChange={(e) =>
                                                    onInputChange(e)
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                )
                            )}
                        </Row>
                        {!paymentInfo.toLocker && (
                            <>
                                <h4 className="mb-3">Kézbesítési adatok</h4>
                                <Row className="mb-3">
                                    {CHECKOUT_CONSTANTS.DELIVERY_INFO.map(
                                        (info, idx) => (
                                            <Col
                                                xs={12}
                                                md={info.fullWidth ? 12 : 6}
                                                key={idx}
                                                className="mb-3"
                                            >
                                                <Form.Group
                                                    className={`form-group ${
                                                        info.isRequired &&
                                                        "required"
                                                    }`}
                                                >
                                                    <Form.Label>
                                                        {info.label}
                                                    </Form.Label>
                                                    <Form.Control
                                                        required={
                                                            info.isRequired
                                                        }
                                                        name={info.name}
                                                        type={info.type}
                                                        placeholder={
                                                            info.placeholder
                                                        }
                                                        onChange={(e) =>
                                                            onInputChange(e)
                                                        }
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        )
                                    )}
                                </Row>
                            </>
                        )}
                        {!paymentInfo.toLocker && (
                            <Row className="mb-3">
                                <Form.Group className="form-group">
                                    <Form.Check
                                        type="checkbox"
                                        id="addressesMatching"
                                    >
                                        <Form.Check.Input
                                            type="checkbox"
                                            name="addressesMatching"
                                            onClick={(e) => {
                                                setIsSameInfo(e.target.value);
                                                setSameInfos();
                                            }}
                                        />
                                        <Form.Check.Label>
                                            A kézbesítési és számlázási adatok
                                            megegyeznek
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                            </Row>
                        )}
                        <h4 className="mb-3">Számlázási adatok</h4>
                        <Row className="mb-3">
                            {CHECKOUT_CONSTANTS.BILLING_INFO.map(
                                (info, idx) => (
                                    <Col
                                        xs={12}
                                        md={info.fullWidth ? 12 : 6}
                                        key={idx}
                                        className="mb-3"
                                    >
                                        <Form.Group
                                            className={`form-group ${
                                                info.isRequired && "required"
                                            }`}
                                        >
                                            <Form.Label>
                                                {info.label}
                                            </Form.Label>
                                            <Form.Control
                                                required={info.isRequired}
                                                name={info.name}
                                                type={info.type}
                                                placeholder={info.placeholder}
                                                defaultValue={
                                                    isSameInfo
                                                        ? paymentInfo[
                                                              info.name
                                                                  .split(
                                                                      "billing"
                                                                  )
                                                                  .pop()
                                                          ]
                                                        : ""
                                                }
                                                onChange={(e) =>
                                                    onInputChange(e)
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                )
                            )}
                        </Row>
                        <Form.Group className="form-group required">
                            <Form.Check
                                required
                                type="checkbox"
                                id="gdprConsent"
                            >
                                <Form.Check.Input
                                    type="checkbox"
                                    name="gdrpConsent"
                                />
                                <Form.Check.Label>
                                    Az{" "}
                                    <Link to="/adatkezelesi-tajekoztato">
                                        adatkezelési tájékoztatóban
                                    </Link>{" "}
                                    foglaltakat elfogadom
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button
                                className="float-right mt-3"
                                variant="primary"
                                type="submit"
                            >
                                Tovább
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}
