import React, { useState, useEffect, useContext, useMemo } from "react";
import {
    Container,
    Col,
    Row,
    ToggleButton,
    ToggleButtonGroup,
    Button,
    Form,
} from "react-bootstrap";
import LoadingIcon from "../components/reusable/LoadingIcon";
import LockerModal from "./LockerModal";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../actions/firebaseConfig";
import { ProductContext } from "../context";
import { useHistory } from "react-router-dom";

async function getShipmentTypes() {
    let shipments = [];
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const allShipments = await getDocs(collection(db, "shipment-types"));
    allShipments.forEach((shipment) => {
        shipments.push(shipment.data());
    });
    return shipments;
}

const calculateShippingCost = (cartCost, shipmentTypes) => {
    return shipmentTypes.map((type) => {
        const currentRanges = Object.keys(type.prices).sort((a, b) => a - b);
        let i = 0;
        while (currentRanges[i] < cartCost) {
            i++;
        }
        return {
            "shipmentType": type.name,
            "shipmentCost": type.prices[currentRanges[i - 1]],
            "shipmentIsLocker": type.isLocker,
        };
    });
};

export default function DeliveryInfo({ nextPage }) {
    const { handleInfoChange, paymentInfo, cartCost } =
        useContext(ProductContext);
    const history = useHistory();
    const [shipmentTypes, setShipmentTypes] = useState();
    const [chosenType, setChosenType] = useState({});
    const [showModal, setShowModal] = useState(false);
    const isFoxpost = useMemo(
        () =>
            Object.keys(chosenType).length > 0
                ? chosenType.shipmentType.includes("Foxpost")
                : null,
        [chosenType]
    );
    const isMpl = useMemo(
        () =>
            Object.keys(chosenType).length
                ? chosenType.shipmentType.includes("MPL")
                : null,
        [chosenType]
    );
    useEffect(async () => {
        let response = await getShipmentTypes();
        const shipmentTypesWithCost = calculateShippingCost(cartCost, response);
        setShipmentTypes(shipmentTypesWithCost);
        return;
    }, []);

    return (
        <>
            {showModal && (
                <LockerModal
                    props={{
                        handleInfoChange,
                        showModal,
                        setShowModal,
                        isFoxpost,
                    }}
                />
            )}
            <Container className="delivery-container">
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Row>
                            <Col>
                                <h3 className="pb-3 text-center">
                                    Kézbesítés módja
                                </h3>
                            </Col>
                        </Row>
                        <ToggleButtonGroup
                            name="shipmentTypes"
                            vertical
                            className="toggle-selector-group"
                        >
                            {shipmentTypes ? (
                                shipmentTypes.map((shipmentType, index) => {
                                    return (
                                        <ToggleButton
                                            key={index}
                                            className="py-3 mb-3"
                                            variant="outline-dark"
                                            type="radio"
                                            name="shipmentType"
                                            id={"shipmentType-" + (index + 1)}
                                            value={shipmentType.shipmentType}
                                            onChange={() => {
                                                paymentInfo.lockerAddress &&
                                                    delete paymentInfo.lockerAddress;
                                                setChosenType(
                                                    shipmentTypes[index]
                                                );
                                            }}
                                        >
                                            <Row className="justify-content-between">
                                                <Col md={3}>
                                                    <p>
                                                        {
                                                            shipmentType.shipmentType
                                                        }
                                                    </p>
                                                    {shipmentType.shipmentType ===
                                                        chosenType.shipmentType &&
                                                        shipmentType.shipmentIsLocker &&
                                                        !!paymentInfo.lockerAddress && (
                                                            <p>{`Kiválasztott automata ${
                                                                isFoxpost
                                                                    ? "címe: "
                                                                    : "száma: "
                                                            } ${
                                                                paymentInfo.lockerAddress
                                                            }`}</p>
                                                        )}
                                                </Col>
                                                <Col md={3}>
                                                    {shipmentType.shipmentCost}
                                                    Ft
                                                </Col>
                                            </Row>
                                        </ToggleButton>
                                    );
                                })
                            ) : (
                                <LoadingIcon />
                            )}
                        </ToggleButtonGroup>
                        <Row>
                            <Row>
                                {isMpl && (
                                    <Col xs={12} md={3}>
                                        <Form.Group className="form-group">
                                            <Form.Label>
                                                Kiválasztott MPL csomagautomata
                                                száma
                                            </Form.Label>
                                            <Form.Control
                                                name="lockerAddress"
                                                type="text"
                                                placeholder="Csomagautomata száma"
                                                onChange={(e) =>
                                                    handleInfoChange(
                                                        "lockerAddress",
                                                        e.target.value
                                                    )
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                            <Col className="d-flex justify-content-end mt-3">
                                {!!paymentInfo.lockerAddress && isFoxpost && (
                                    <Button
                                        variant="outline-primary"
                                        className="mx-3"
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                    >
                                        Automata módosítása
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    onClick={() => {
                                        handleInfoChange(
                                            "shipmentType",
                                            chosenType.shipmentType
                                        );
                                        handleInfoChange(
                                            "shipmentCost",
                                            chosenType.shipmentCost
                                        );
                                        handleInfoChange(
                                            "toLocker",
                                            chosenType.shipmentIsLocker
                                        );
                                        (chosenType.shipmentIsLocker &&
                                            paymentInfo.lockerAddress) ||
                                        !chosenType.shipmentIsLocker
                                            ? history.push(nextPage)
                                            : isFoxpost
                                            ? setShowModal(true)
                                            : window.open(
                                                  "https://www.posta.hu/szolgaltatasok/szolgaltataskereso?selectedPostTypes=postamachine",
                                                  "_blank"
                                              );
                                    }}
                                    disabled={
                                        Object.keys(chosenType).length === 0
                                    }
                                >
                                    {Object.keys(chosenType).length === 0 ||
                                    !chosenType.shipmentIsLocker ||
                                    (chosenType.shipmentIsLocker &&
                                        !!paymentInfo.lockerAddress)
                                        ? "Tovább"
                                        : isFoxpost
                                        ? "Foxpost automata kiválasztása"
                                        : "MPL automaták keresése"}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
