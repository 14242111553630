import React, { useState, useEffect } from "react";
import { createClient } from "contentful";

import { submitOrder } from "./actions/submitOrder";

export const ProductContext = React.createContext();

export default function ProductProvider(props) {
    //Cart context
    const [cart, setCart] = useState(() => {
        if (window.localStorage.getItem("cart") === null) {
            return [];
        } else {
            const timestamp = window.localStorage.getItem("timestamp");
            const now = new Date().getTime();
            // Check if timestamp is older than 24 hours
            if (Math.abs(timestamp - now) > 24 * 60 * 60 * 1000) {
                window.localStorage.setItem("cart", JSON.stringify([]));
                return [];
            } else {
                return JSON.parse(window.localStorage.getItem("cart"));
            }
        }
    });
    const [sumCost, setSumCost] = useState(0);
    const [cartCost, setCartCost] = useState(0);

    function addToCart(product, quantity) {
        if (cart.length == 0) {
            product["quantity"] = parseInt(quantity);
            setCart([product]);
        } else {
            if (cart.some((el) => el.sys.id === product.sys.id)) {
                let newCart = [...cart];
                let cartItemIndex = newCart.findIndex(
                    (x) => x.sys.id === product.sys.id
                );
                newCart[cartItemIndex]["quantity"] += parseInt(quantity);
                setCart(newCart);
            } else {
                product["quantity"] = parseInt(quantity);
                setCart([...cart, product]);
            }
        }
    }

    function handleQtyChange(value, id) {
        let cartCopy = [...cart];
        let elementIndex = cartCopy.findIndex((item) => item.sys.id === id);
        if (value === 0) {
            cartCopy.splice(elementIndex, 1);
            setCart(cartCopy);
        } else {
            cartCopy[elementIndex].quantity = parseInt(value);
            setCart(cartCopy);
        }
    }

    function emptyCart() {
        setCart([]);
    }

    const client = createClient({
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        space: process.env.REACT_APP_CONTENTFUL_SPACE,
    });

    //Payment info context

    const [paymentInfo, setPaymentInfo] = useState({});

    const emptyPaymentInfo = () => {
        setPaymentInfo({});
    };

    const calculateCartCost = () => {
        if (cart.length > 0) {
            let p = 0;
            cart.forEach((cartItem) => {
                p = p + cartItem.fields.price * cartItem.quantity;
            });
            setCartCost(p);
        } else {
            setCartCost(0);
        }
    };

    const calculateSumCost = () => {
        const sumCost = cartCost + paymentInfo["shipmentCost"];
        setPaymentInfo((paymentInfo) => ({
            ...paymentInfo,
            ["sum-cost"]: sumCost,
        }));
        setSumCost(sumCost);
    };

    useEffect(() => {
        const updateLocalStore = () => {
            window.localStorage.setItem("cart", JSON.stringify(cart));
            window.localStorage.setItem(
                "timestamp",
                JSON.stringify(new Date().getTime())
            );
        };
        updateLocalStore();
        calculateCartCost();
    }, [cart]);

    useEffect(
        () => paymentInfo.shipmentType && calculateSumCost(),
        [paymentInfo["shipmentType"]]
    );

    const confirmPayment = async () => {
        await submitOrder({
            orderSubmited: Date.now(),
            ...paymentInfo,
            isToBeDelivered: false,
            items: cart,
        }).then(() => {
            emptyCart();
            emptyPaymentInfo();
            return true;
        });
    };

    const handleInfoChange = (name, value) => {
        setPaymentInfo((paymentInfo) => ({ ...paymentInfo, [name]: value }));
    };

    //Cart portal context
    const [cartSlideIn, setCartSlideIn] = useState(false);

    const handleCartClose = () => setCartSlideIn(false);
    const handleCartShow = () => setCartSlideIn(true);

    //Mobile search helpers
    const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false);
    const [searchShow, setSearchShow] = useState(false);
    const [searchResultsShow, setSearchResultsShow] = useState(false);

    return (
        <ProductContext.Provider
            value={{
                addToCart,
                cart,
                setCart,
                sumCost,
                cartCost,
                handleQtyChange,
                client,
                emptyCart,
                paymentInfo,
                setPaymentInfo,
                confirmPayment,
                handleInfoChange,
                handleCartClose,
                handleCartShow,
                cartSlideIn,
                isMobileNavbarOpen,
                setIsMobileNavbarOpen,
                searchShow,
                setSearchShow,
                searchResultsShow,
                setSearchResultsShow,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    );
}
