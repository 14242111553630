import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductCategoryCard from "./reusable/ProductCategoryCard";
import JewelleryBg from "./category-images/jewellery-bg.jpg";
import TextileBg from "./category-images/textile-bg.jpg";

export default function ProductWrapper() {
    return (
        <Container className="product-container" fluid>
            <Row className="header-text gy-5 text-center">
                <h2>Termékeink</h2>
            </Row>
            <Row className="justify-content-evenly pb-5">
                <Col xs={12} md={4} className="px-5 pt-3 pb-3 pb-md-0">
                    <Link
                        to="/vasarlas?category=Ékszer"
                        className="category-card"
                    >
                        <ProductCategoryCard
                            backgroundImage={JewelleryBg}
                            category="Ékszerek"
                        />
                    </Link>
                </Col>
                <Col xs={12} md={4} className="px-5 pt-3 ">
                    <Link
                        to="/vasarlas?category=Textília"
                        className="category-card"
                    >
                        <ProductCategoryCard
                            backgroundImage={TextileBg}
                            category="Textíliák"
                        />
                    </Link>
                </Col>
            </Row>
        </Container>
    );
}
