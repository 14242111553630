import React, { useState, useEffect, useContext } from "react";
import HeroWrapper from "../HeroWrapper";
import ProductWrapper from "../ProductWrapper";
import NewestWrapper from "../NewestWrapper";
import AboutWrapper from "../AboutWrapper";
import { ProductContext } from "../../context";
import ContactModal from "../reusable/ContactModal";

export default function Home() {
    var { client } = useContext(ProductContext);
    const [pageContent, setPageContent] = useState({});
    const [showContact, setShowContact] = useState(false);

    useEffect(() => {
        async function fetchContent() {
            let response = await client.getEntry("7jDT8I83kWCVLqU0IZflbx");
            setPageContent(response.fields);
        }
        fetchContent();
    }, []);
    return (
        <React.Fragment>
            <ContactModal contactBanner={{ showContact, setShowContact }} />
            <HeroWrapper
                slideshowImages={pageContent.slideshowImages}
                contactBanner={{ showContact, setShowContact }}
            />
            <ProductWrapper />
            <NewestWrapper />
            <AboutWrapper />
        </React.Fragment>
    );
}
