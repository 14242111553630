import { addDoc, collection } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from "./firebaseConfig";
// import { reCaptcha } from "./reCaptcha";

export const contactSubmission = async (msgContent) => {
    // await reCaptcha()
        // .then(() => {
            const app = initializeApp(firebaseConfig);
            const db = getFirestore(app);
            const contactRef = collection(db, "contact-forms");
            let docs = addDoc(contactRef, msgContent);
            return docs;
        // })
        // .catch((err) => {
        //     console.log(err);
        // });
};
