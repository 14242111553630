import React from "react";
import { Modal } from "react-bootstrap";
import ContactForm from "./ContactForm";

export default function ContactModal({ contactBanner }) {
    const { showContact, setShowContact } = contactBanner;
    return (
        <Modal
            show={showContact}
            onHide={() => {
                setShowContact(false);
            }}
            centered
            dialogClassName="contact-modal"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <ContactForm setShowContact={setShowContact} />
            </Modal.Body>
        </Modal>
    );
}
