import React, { useContext, useEffect } from "react";
import { ListGroup, Pagination, Row } from "react-bootstrap";
import SearchItem from "./SearchItem";
import { ProductContext } from "../../../context";

export default function SearchOverlay(props) {
    const {
        searchShow,
        searchResults,
        searchQueryLength,
        searchPageNumber,
        setSearchPageNumber,
        shouldNextPageBeDisabled,
    } = props;
    const { searchResultsShow, setSearchResultsShow, isMobileNavbarOpen } =
        useContext(ProductContext);

    useEffect(() => {
        setSearchResultsShow(searchShow && searchQueryLength >= 3);
    }, [searchShow, searchQueryLength]);

    useEffect(() => {
        isMobileNavbarOpen && searchResultsShow
            ? (document.body.style.overflowY = "hidden")
            : (document.body.style.overflowY = "auto");
    }, [searchResultsShow]);

    return (
        <div
            className="search-results"
            style={
                searchResultsShow ? { display: "block" } : { display: "none" }
            }
        >
            <>
                {searchResults ? (
                    <ListGroup>
                        <ListGroup.Item>Találatok</ListGroup.Item>
                        {searchResults.map((searchResult, index) => {
                            return (
                                <SearchItem
                                    key={index}
                                    prodImg={
                                        searchResult.fields.productImage.fields
                                            .file.url
                                    }
                                    name={searchResult.fields.productName}
                                    price={searchResult.fields.price}
                                    url={searchResult.fields.url}
                                />
                            );
                        })}
                        <ListGroup.Item>
                            <Row>
                                <Pagination size="md">
                                    <Pagination.Prev
                                        disabled={searchPageNumber === 1}
                                        onClick={() =>
                                            setSearchPageNumber(
                                                searchPageNumber - 1
                                            )
                                        }
                                        className="text-center searchbar-pagination"
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            setSearchPageNumber(
                                                searchPageNumber + 1
                                            )
                                        }
                                        className="text-center searchbar-pagination"
                                        disabled={shouldNextPageBeDisabled}
                                    />
                                </Pagination>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                ) : (
                    <div>Egyetlen termék sem felel meg a kritériumnak.</div>
                )}
            </>
        </div>
    );
}
