import React, { useState, useEffect, useContext } from "react";
import LoadingIcon from "./reusable/LoadingIcon";
import { ProductContext } from "../context";
import { Accordion } from "react-bootstrap";

export default function Sidebar({
    props: {
        setFilterStatus,
        filterStatus,
        filterHandler,
        selectedFilters,
        updateQueryParams,
    },
}) {
    const { client } = useContext(ProductContext);
    const [allFilters, setAllFilters] = useState({
        category: ["Ékszer", "Textília", "Egyéb kiegészítők"],
        type: [
            "Fülbevaló",
            "Nyaklánc",
            "Gyűrű",
            "Párna",
            "Táska",
            "Könyvszütyő",
            "Mikulás zsák",
            "Ékszerszett",
            "Karkötő",
            "Neszeszer",
            "Táskatartó",
            "Kulcstartó",
            "Könyvjelző",
            "Hajcsat",
            "Kitűző",
            "Hűtőmágnes",
            "Arctisztító korong",
        ],
        material: [
            "Ásványgyöngy",
            "Üveglencse",
            "Dekorvászon",
            "Pamutvászon",
            "Cérnabojt",
            "Fonal",
            "Rojt",
            "Egyéb",
        ],
    });
    const filterNames = {
        category: "Kategória",
        type: "Típus",
        material: "Anyag",
        color: "Szín",
        pattern: "Minta",
    };
    const [contentTypes, setContentTypes] = useState({});
    var filterList = ["color", "pattern"];

    useEffect(() => {
        return filterList.forEach((filter) => {
            queryFilters(filter);
            queryContentTypes(filter);
        });
    }, []);

    useEffect(() => {
        if (
            Object.keys(allFilters).length === 5 &&
            Object.keys(contentTypes).length === filterList.length
        ) {
            setFilterStatus(true);
        }
        return true;
    }, [contentTypes, allFilters]);

    async function queryContentTypes(filter) {
        let response = await client.getContentType(filter);
        setContentTypes((contentTypes) => ({
            ...contentTypes,
            [filter]: response,
        }));
    }

    async function queryFilters(filter) {
        let response = await client.getEntries({
            content_type: filter,
        });
        setAllFilters((allFilters) => ({
            ...allFilters,
            [filter]: response.items,
        }));
    }

    return (
        <aside className="sidebar-container">
            {!filterStatus ? (
                <LoadingIcon />
            ) : (
                <div className="filters">
                    <h3 className="sidebar-title">Szűrők</h3>
                    <Accordion alwaysOpen={true} flush>
                        {Object.keys(allFilters).map((filter) => {
                            return filterList.includes(filter) ? (
                                <Accordion.Item
                                    eventKey={Object.keys(allFilters).indexOf(
                                        filter
                                    )}
                                >
                                    <Accordion.Header>
                                        <h4>{filterNames[filter]}</h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="filter-items">
                                            {allFilters[filter].map(
                                                (filterField, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        updateQueryParams(
                                                                            e
                                                                                .target
                                                                                .name,
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        );
                                                                        filterHandler(
                                                                            e
                                                                                .target
                                                                                .name,
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    name={
                                                                        filter
                                                                    }
                                                                    value={
                                                                        filterField
                                                                            .fields
                                                                            .value
                                                                    }
                                                                    id={
                                                                        filterField
                                                                            .fields
                                                                            .value
                                                                    }
                                                                    checked={selectedFilters[
                                                                        filter
                                                                    ].includes(
                                                                        filterField
                                                                            .fields
                                                                            .value
                                                                    )}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={
                                                                        filterField
                                                                            .fields
                                                                            .value
                                                                    }
                                                                >
                                                                    {
                                                                        filterField
                                                                            .fields
                                                                            .name
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ) : (
                                <Accordion.Item
                                    eventKey={Object.keys(allFilters).indexOf(
                                        filter
                                    )}
                                >
                                    <Accordion.Header>
                                        <h4>{filterNames[filter]}</h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="filter-items">
                                            {allFilters[filter].map(
                                                (filterField, idx) => {
                                                    return (
                                                        <li
                                                            className="form-check"
                                                            key={idx}
                                                        >
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    updateQueryParams(
                                                                        e.target
                                                                            .name,
                                                                        e.target
                                                                            .value,
                                                                        e.target
                                                                            .checked
                                                                    );
                                                                    filterHandler(
                                                                        e.target
                                                                            .name,
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                name={filter}
                                                                value={
                                                                    filterField
                                                                }
                                                                id={filterField}
                                                                checked={selectedFilters[
                                                                    filter
                                                                ].includes(
                                                                    filterField
                                                                )}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={
                                                                    filterField
                                                                }
                                                            >
                                                                {filterField}
                                                            </label>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                </div>
            )}
        </aside>
    );
}
