import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

export default function LockerModal({
    props: { handleInfoChange, showModal, setShowModal },
}) {
    useEffect(() => {
        window.addEventListener(
            "message",
            (event) => {
                if (event.origin !== "https://cdn.foxpost.hu") {
                    return;
                } else {
                    var apt = JSON.parse(event.data);
                    handleInfoChange("lockerAddress", apt.address);
                    setShowModal(false);
                }
            },
            false
        );
        return true;
    }, []);

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
            dialogClassName="locker-modal"
            centered
            fullscreen="md-down"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <iframe
                    frameBorder="0"
                    loading="lazy"
                    src="https://cdn.foxpost.hu/apt-finder/v1/app/?discount=1"
                    width="100%"
                    height="100%"
                ></iframe>
            </Modal.Body>
        </Modal>
    );
}
