import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Container, Table, Button } from "react-bootstrap";
import CartList from "../components/CartList";
import { useHistory } from "react-router-dom";

import { ProductContext } from "../context";

export default function OrderSummary({ nextPage }) {
    const { paymentInfo, cart, confirmPayment } = useContext(ProductContext);
    const history = useHistory();
    const calcPrice = () => {
        let p = 0;
        cart.forEach((cartItem) => {
            p = p + cartItem.fields.price * cartItem.quantity;
        });
        p += paymentInfo["shipmentCost"];
        return p;
    };
    const [price, setPrice] = useState(calcPrice());
    useEffect(() => {
        setPrice(calcPrice());
    }, [cart]);
    const submitOrder = () => {
        confirmPayment();
        //TODO: Ennek aszinkornnak kéne lennie
        history.push(nextPage);
    };
    return (
        <Container className="order-container">
            {!!paymentInfo && (
                <Row className="justify-content-center">
                    <Col md={8}>
                        <div className="py-3 text-center">
                            <h3>Vásárlási összegző</h3>
                        </div>
                        <CartList />
                        <Col className="pt-3">
                            <Row className="justify-content-between">
                                <Col>
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <Table>
                                                <thead>
                                                    <th scope="col">
                                                        Számlázási adatok
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <td>
                                                        <ul className="mb-0">
                                                            <li>
                                                                {" "}
                                                                <b>Név: </b>
                                                                {paymentInfo.firstName +
                                                                    " " +
                                                                    paymentInfo.lastName}
                                                            </li>
                                                            <li>
                                                                <b>Cím: </b>
                                                                {paymentInfo.billingpostalCode +
                                                                    " " +
                                                                    paymentInfo.billingcity +
                                                                    ", " +
                                                                    paymentInfo.billingplaceName +
                                                                    " " +
                                                                    paymentInfo.billingplaceType +
                                                                    " " +
                                                                    paymentInfo.billinghouseNumber}
                                                            </li>
                                                            {!!paymentInfo.billingbuildingNumber ||
                                                            !!paymentInfo.billingstairNumber ||
                                                            !!paymentInfo.billingfloorNumber ||
                                                            !!paymentInfo.billingdoorNumber ? (
                                                                <li>
                                                                    {
                                                                        paymentInfo.billingbuildingNumber
                                                                    }
                                                                    {paymentInfo.billingbuildingNumber
                                                                        ? ` ${paymentInfo.billingstairNumber}`
                                                                        : paymentInfo.billingstairNumber}
                                                                    {paymentInfo.billingstairNumber
                                                                        ? ` ${paymentInfo.billingfloorNumber}`
                                                                        : paymentInfo.billingfloorNumber}
                                                                    {paymentInfo.billingfloorNumber
                                                                        ? ` ${paymentInfo.billingdoorNumber}`
                                                                        : paymentInfo.billingdoorNumber}
                                                                </li>
                                                            ) : null}
                                                            <li>
                                                                <b>
                                                                    E-mail cím:{" "}
                                                                </b>
                                                                {
                                                                    paymentInfo.email
                                                                }
                                                            </li>
                                                            <li>
                                                                <b>
                                                                    Telefonszám:{" "}
                                                                </b>
                                                                {
                                                                    paymentInfo.phoneNum
                                                                }
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Table>
                                                <thead>
                                                    <th scope="col">
                                                        Kézbesítés
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <td>
                                                        <ul>
                                                            <li>
                                                                {
                                                                    paymentInfo[
                                                                        "shipmentType"
                                                                    ]
                                                                }
                                                            </li>
                                                            {paymentInfo.toLocker ? (
                                                                <li>
                                                                    {`Automata: ${paymentInfo.lockerAddress}`}
                                                                </li>
                                                            ) : (
                                                                <li>
                                                                    {paymentInfo.postalCode +
                                                                        " " +
                                                                        paymentInfo.city +
                                                                        ", " +
                                                                        paymentInfo.placeName +
                                                                        " " +
                                                                        paymentInfo.placeType +
                                                                        " " +
                                                                        paymentInfo.houseNumber}
                                                                    {!!paymentInfo.buildingNumber ||
                                                                    !!paymentInfo.stairNumber ||
                                                                    !!paymentInfo.floorNumber ||
                                                                    !!paymentInfo.doorNumber ? (
                                                                        <div>
                                                                            {
                                                                                paymentInfo.buildingNumber
                                                                            }
                                                                            {paymentInfo.buildingNumber
                                                                                ? ` ${paymentInfo.stairNumber}`
                                                                                : paymentInfo.stairNumber}
                                                                            {paymentInfo.stairNumber
                                                                                ? ` ${paymentInfo.floorNumber}`
                                                                                : paymentInfo.floorNumber}
                                                                            {paymentInfo.floorNumber
                                                                                ? ` ${paymentInfo.doorNumber}`
                                                                                : paymentInfo.doorNumber}
                                                                        </div>
                                                                    ) : null}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </td>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col
                                            xs={12}
                                            md={{ span: 3, offset: 3 }}
                                        >
                                            <div>
                                                Szállítási költség:{" "}
                                                {paymentInfo["shipmentCost"]} Ft
                                            </div>
                                            <div>Összesen: {price} Ft</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Row className="pt-3">
                            <Col className="d-flex justify-content-end">
                                <Button
                                    disabled={cart.length === 0}
                                    onClick={() => {
                                        submitOrder();
                                    }}
                                >
                                    Rendelés elküldése
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Container>
    );
}
