import React from "react";

export default function ProductCategoryCard(props) {
    return (
        <div
            className='product-category-card'
            style={{ backgroundImage: `url(${props.backgroundImage})` }}
        >
            <h3 className='product-category-name'>{props.category}</h3>
        </div>
    );
}
