import React from "react";
import ProductProvider from "./context";
import App from "./App";

export default function AppWrapper() {
    return (
        <ProductProvider>
            <App />
        </ProductProvider>
    );
}
