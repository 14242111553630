const PERSONAL_INFO = [
    {
        label: "Vezetéknév",
        name: "firstName",
        type: "text",
        placeholder: "Vezetéknév",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Keresztnév",
        name: "lastName",
        type: "text",
        placeholder: "Keresztnév",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Email cím",
        name: "email",
        type: "email",
        placeholder: "Email",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Telefonszám",
        name: "phoneNum",
        type: "text",
        placeholder: "Telefonszám",
        isRequired: true,
        fullWidth: false,
    },
];

const DELIVERY_INFO = [
    {
        label: "Település",
        name: "city",
        type: "text",
        placeholder: "Település",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Irányítószám",
        name: "postalCode",
        type: "text",
        placeholder: "Irányítószám",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Közterület neve",
        name: "placeName",
        type: "text",
        placeholder: "Közterület neve",
        isRequired: true,
        fullWidth: true,
    },
    {
        label: "Közterület jellege",
        name: "placeType",
        type: "text",
        placeholder: "Út, utca, tér, stb.",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Házszám",
        name: "houseNumber",
        type: "text",
        placeholder: "Házszám",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Épület (opcionális)",
        name: "buildingNumber",
        type: "text",
        placeholder: "Épület",
        isRequired: false,
        fullWidth: false,
    },
    {
        label: "Lépcsőház (opcionális)",
        name: "stairNumber",
        type: "text",
        placeholder: "Lépcsőház",
        isRequired: false,
        fullWidth: false,
    },
    {
        label: "Emelet (opcionális)",
        name: "floorNumber",
        type: "text",
        placeholder: "Emelet",
        isRequired: false,
        fullWidth: false,
    },
    {
        label: "Ajtó (opcionális)",
        name: "doorNumber",
        type: "text",
        placeholder: "Ajtó",
        isRequired: false,
        fullWidth: false,
    },
];

const BILLING_INFO = [
    {
        label: "Település",
        name: "billingcity",
        type: "text",
        placeholder: "Település",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Irányítószám",
        name: "billingpostalCode",
        type: "text",
        placeholder: "Irányítószám",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Közterület neve",
        name: "billingplaceName",
        type: "text",
        placeholder: "Közterület neve",
        isRequired: true,
        fullWidth: true,
    },
    {
        label: "Közterület jellege",
        name: "billingplaceType",
        type: "text",
        placeholder: "Út, utca, tér, stb.",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Házszám",
        name: "billinghouseNumber",
        type: "text",
        placeholder: "Házszám",
        isRequired: true,
        fullWidth: false,
    },
    {
        label: "Épület (opcionális)",
        name: "billingbuildingNumber",
        type: "text",
        placeholder: "Épület",
        isRequired: false,
        fullWidth: false,
    },
    {
        label: "Lépcsőház (opcionális)",
        name: "billingstairNumber",
        type: "text",
        placeholder: "Lépcsőház",
        isRequired: false,
        fullWidth: false,
    },
    {
        label: "Emelet (opcionális)",
        name: "billingfloorNumber",
        type: "text",
        placeholder: "Emelet",
        isRequired: false,
        fullWidth: false,
    },
    {
        label: "Ajtó (opcionális)",
        name: "billingdoorNumber",
        type: "text",
        placeholder: "Ajtó",
        isRequired: false,
        fullWidth: false,
    },
];

export default {
    PERSONAL_INFO,
    DELIVERY_INFO,
    BILLING_INFO,
};
