import React, { useEffect, useState, useContext } from "react";
import ProductCard from "./reusable/ProductCard";
import { Container, Row, Col } from "react-bootstrap";
import { ProductContext } from "../context";
import LoadingIcon from "./reusable/LoadingIcon";

export default function SimilarProducts({ props }) {
    const { client } = useContext(ProductContext);
    const [relatedContent, setRelatedContent] = useState([]);

    useEffect(() => {
        queryProducts();
    }, []);
    async function queryProducts() {
        let response = await client.getEntries({
            content_type: "product",
            "sys.id[nin]": props.sys.id,
            "fields.category": props.fields.category,
            limit: 3,
        });
        setRelatedContent(response.items);
    }

    return (
        <div className="mt-5">
            <div className="text-center">
                <h2>Hasonló termékeink</h2>
            </div>
            {!relatedContent ? (
                <LoadingIcon />
            ) : (
                <Container>
                    {(() => {
                        if (relatedContent.length !== 0) {
                            return (
                                <Row className="justify-content-center">
                                    {relatedContent.map((product, idx) => {
                                        return (
                                            <Col
                                                className="product-card-col"
                                                md={3}
                                                key={idx}
                                            >
                                                <a
                                                    href={
                                                        "/vasarlas/" +
                                                        product.fields.url
                                                    }
                                                >
                                                    <ProductCard
                                                        key={product.sys.id}
                                                        title={
                                                            product.fields
                                                                .productName
                                                        }
                                                        img={
                                                            "https:" +
                                                            product.fields
                                                                .productImage
                                                                .fields.file
                                                                .url +
                                                            "?w=844&h=556"
                                                        }
                                                        price={
                                                            product.fields.price
                                                        }
                                                    />
                                                </a>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            );
                        } else {
                            return (
                                <div className="text-center pt-3">
                                    Hasonló termék nem található a kínálatban.
                                    Bővebb kínálatért látogass el{" "}
                                    <a href="https://www.facebook.com/hopekezmuves/">
                                        Facebook oldalunkra!
                                    </a>
                                </div>
                            );
                        }
                    })()}
                </Container>
            )}
        </div>
    );
}
