import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <Container className="footer" fluid>
            <Container>
                <Row className="text-center py-5">
                    <Col className="text-start">
                        <Link to="/szerzodesi-feltetelek">
                            Általános szerződési feltételek (ÁSZF)
                        </Link>
                        <br />
                        <Link to="/adatkezelesi-tajekoztato">
                            Adatkezelési tájékoztató
                        </Link>
                    </Col>
                    <Col></Col>
                    <Col>2023 Ho-Pe 2002 Bt.</Col>
                </Row>
            </Container>
        </Container>
    );
}
