import React from "react";
import {
    Button,
    Carousel,
    Col,
    Container,
    Image,
    Row,
    ButtonToolbar,
} from "react-bootstrap";
import LoadingIcon from "./reusable/LoadingIcon";

export default function HeroWrapper({ slideshowImages, contactBanner }) {
    const { setShowContact } = contactBanner;
    return (
        <Container className="hero-container" fluid>
            {!slideshowImages ? (
                <LoadingIcon />
            ) : (
                <Row className="justify-content-between h-100">
                    <Col
                        md={{ order: 1, span: 6 }}
                        xs={{ order: 2, span: 12 }}
                        className="text-col d-flex justify-content-center align-items-center"
                    >
                        <Col md={10} lg={8}>
                            <Row className="text-container py-3 px-5">
                                <Col xs={12} className="text-center">
                                    <h1>Álmodd meg, mi elkészítjük!</h1>
                                </Col>
                                <Col xs={12}>
                                    <ButtonToolbar className="justify-content-around d-flex">
                                        <Button
                                            variant="primary"
                                            className="mb-3"
                                            href="/vasarlas"
                                        >
                                            Vásárlás
                                        </Button>
                                        <Button
                                            variant="outline-primary"
                                            className="mb-3"
                                            onClick={() => {
                                                setShowContact(true);
                                            }}
                                        >
                                            Írj nekünk!
                                        </Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                    <Col
                        md={{ order: 2, span: 4 }}
                        xs={{ order: 1, span: 12 }}
                        className="d-flex justify-content-end"
                    >
                        <div className="slideshow-container">
                            <Carousel
                                controls={false}
                                indicators={false}
                                fade={true}
                            >
                                {slideshowImages.map((slideshowImg, index) => {
                                    return (
                                        <Carousel.Item interval={1500} key={index}>
                                            <picture>
                                                <source
                                                    media="(max-width: 576px)"
                                                    srcSet={`https:${slideshowImg.fields.file.url}?w=633&h=417`}
                                                />
                                                <source
                                                    media="(max-width: 768px)"
                                                    srcSet={`https:${slideshowImg.fields.file.url}?w=844&h=556`}
                                                />
                                                <source
                                                    media="(max-width: 992px)"
                                                    srcSet={`https:${slideshowImg.fields.file.url}?w=780&h=828`}
                                                />
                                                <source
                                                    media="(min-width: 1200px)"
                                                    srcSet={`https:${slideshowImg.fields.file.url}?w=1560&h=1656`}
                                                />
                                                <Image
                                                    src={`https:${slideshowImg.fields.file.url}?w=780&h=828`}
                                                    className="d-block"
                                                    fluid
                                                />
                                            </picture>
                                        </Carousel.Item>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
}
