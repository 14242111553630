import React, { useState, useContext, useEffect, useMemo } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container, Badge, FormControl, Image, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import logo from "../../hope-logo.png";
import { Icon } from "react-icons-kit";
import { shoppingBag } from "react-icons-kit/feather";
import CartSlideIn from "../CartSlideIn";
import SearchOverlay from "./Search/SearchOverlay";
import { ProductContext } from "../../context";
import classNames from "classnames";

export default function Navigation() {
    const {
        client,
        cart,
        cartSlideIn,
        handleCartClose,
        handleCartShow,
        setIsMobileNavbarOpen,
        isMobileNavbarOpen,
        searchShow,
        setSearchShow,
        searchResultsShow,
        setSearchResultsShow,
    } = useContext(ProductContext);
    const [searchPageNumber, setSearchPageNumber] = useState(1);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQueryLength, setSearchQueryLength] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchTotalResults, setSearchTotalResults] = useState(0);

    const searchResultsLimit = useMemo(() => 3, []);

    async function handleSearch() {
        setSearchQueryLength(searchQuery.length);
        let response = await client.getEntries({
            content_type: "product",
            query: searchQuery,
            limit: searchResultsLimit,
            skip: (searchPageNumber - 1) * searchResultsLimit,
        });
        setSearchResults(response.items);
        setSearchTotalResults(response.total);
    }

    useEffect(() => {
        handleSearch();
    }, [searchQuery, searchPageNumber]);

    const shouldNextPageBeDisabled = useMemo(() => {
        return (
            searchResultsLimit * (searchPageNumber + 1) >
            searchTotalResults + searchResultsLimit
        );
    }, [searchResultsLimit, searchPageNumber, searchTotalResults]);

    return (
        <Container
            className={classNames("nav-full", {
                "navbar-mobile-search-open":
                    searchResultsShow && isMobileNavbarOpen,
            })}
        >
            <CartSlideIn props={{ handleCartClose, cartSlideIn }} />
            <Navbar
                expand="md"
                onToggle={(toggleValue) => {
                    setIsMobileNavbarOpen(toggleValue);
                    toggleValue === false && setSearchResultsShow(false);
                }}
            >
                <Container className="nav-container" fluid="md">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Brand href="/" className="mx-auto">
                        <Image src={logo} fluid />
                    </Navbar.Brand>
                    <Nav className="cart-mobile">
                        <Button variant="light" onClick={handleCartShow}>
                            <Icon icon={shoppingBag} size={24} />
                            <Badge pill>
                                {!!Object.keys(cart).length &&
                                    Object.keys(cart).length}
                            </Badge>
                        </Button>
                    </Nav>
                    <Navbar.Collapse>
                        <Nav className="mx-auto">
                            <Nav.Link href="/">Kezdőlap</Nav.Link>
                            <Nav.Link href="/vasarlas">Vásárlás</Nav.Link>
                            <Nav.Link href="/elerhetosegek">Kapcsolat</Nav.Link>
                        </Nav>
                        <Nav
                            onBlur={(e) => {
                                (e.relatedTarget === null ||
                                    !e.relatedTarget.parentNode.classList.contains(
                                        "searchbar-pagination"
                                    )) &&
                                    setTimeout(() => setSearchShow(false), 200);
                            }}
                        >
                            <Form className="navbar-search">
                                <FormControl
                                    placeholder="Keresés"
                                    aria-label="keresés"
                                    id="search-field"
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                    }}
                                    onFocus={() => setSearchShow(true)}
                                />
                                <SearchOverlay
                                    searchShow={searchShow}
                                    searchResults={searchResults}
                                    searchQueryLength={searchQueryLength}
                                    searchPageNumber={searchPageNumber}
                                    setSearchPageNumber={setSearchPageNumber}
                                    shouldNextPageBeDisabled={
                                        shouldNextPageBeDisabled
                                    }
                                />
                            </Form>
                        </Nav>
                        <Nav className="cart-desktop ps-3">
                            <Button
                                variant="light"
                                onClick={() => handleCartShow()}
                            >
                                <Icon icon={shoppingBag} size={24} />
                                <Badge pill>
                                    {!!Object.keys(cart).length &&
                                        Object.keys(cart).length}
                                </Badge>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Container>
    );
}
