import React, { useContext, useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { ProductContext } from "../../context";
import LoadingIcon from "./LoadingIcon";
import { Link } from "react-router-dom";

export default function NewestProduct() {
    const { client } = useContext(ProductContext);
    const [newestProducts, setNewestProducts] = useState(null);
    useEffect(() => {
        async function fetchContent() {
            let response = await client.getEntries({
                content_type: "product",
                order: "-sys.createdAt",
                limit: 5,
            });
            setNewestProducts(response.items);
        }
        fetchContent();
    }, []);

    return (
        <React.Fragment>
            {newestProducts === null ? (
                <LoadingIcon />
            ) : (
                <Carousel className="pb-3">
                    {newestProducts.map((product, idx) => {
                        return (
                            <Carousel.Item key={idx}>
                                <Link to={`/vasarlas/${product.fields.url}`}>
                                    <img
                                        className="d-block w-100"
                                        src={
                                            product.fields.productImage.fields
                                                .file.url &&
                                            `https:${product.fields.productImage.fields.file.url}?w=618&h=410`
                                        }
                                        alt={product.fields.productName}
                                    />
                                    <Carousel.Caption>
                                        <h5>{product.fields.productName}</h5>
                                        <p>{product.fields.price} Ft</p>
                                    </Carousel.Caption>
                                </Link>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            )}
        </React.Fragment>
    );
}
