import React, { useState, useEffect } from "react";
import ProductCard from "./reusable/ProductCard";
import { Row, Col, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingIcon from "./reusable/LoadingIcon";

export default function ShopContainer({
    props: { productList, pageNumber, setPageNumber, totalResults, itemsLimit, isProductsLoading },
}) {
    const [pagesList, setPagesList] = useState([]);
    const maxPageNumber = Math.ceil(totalResults / itemsLimit);
    function buildPagination() {
        let pageNumbering = [];
        for (let i = -2; i <= 2; i++) {
            if (pageNumber + i > 0 && pageNumber + i <= maxPageNumber) {
                pageNumbering.push(pageNumber + i);
            }
        }
        setPagesList(pageNumbering);
    }
    useEffect(() => {
        buildPagination();
    }, [pageNumber, totalResults]);
    const updatePageNumber = (pageNumber) => {
        setPageNumber(pageNumber);
        window.scrollTo(0,0)
    }
    return (
        <div className="shop-container">
            {totalResults === 0 ? (
                <div>
                    Egyetlen termék sem felel meg a keresési feltételeknek.
                </div>
            ) : (
                <div>
                    {isProductsLoading ? <LoadingIcon /> :                     
                    <Row>
                        {productList.map((product, index) => {
                            return (
                                <Col
                                    className="product-card-col"
                                    md={4}
                                    sm={6}
                                    xl={3}
                                    xs={12}
                                    key={index}
                                >
                                    <Link to={`vasarlas/${product.fields.url}`}>
                                        <ProductCard
                                            key={index}
                                            title={product.fields.productName}
                                            img={
                                                "https:" +
                                                product.fields.productImage
                                                    .fields.file.url +
                                                "?w=844&h=556"
                                            }
                                            price={product.fields.price}
                                        />
                                    </Link>
                                </Col>
                            );
                        })}
                    </Row>
}                    <Row>
                        <Pagination className="justify-content-center">
                            <Pagination.Prev
                                onClick={() => {
                                    updatePageNumber(pageNumber - 1);
                                }}
                                disabled={pageNumber === 1 ? true : false}
                            />
                            {pagesList.map((num, idx) => {
                                return (
                                    <Pagination.Item
                                        active={
                                            num === pageNumber ? true : false
                                        }
                                        onClick={() => {
                                            updatePageNumber(num);
                                        }}
                                        key={idx}
                                    >
                                        {num}
                                    </Pagination.Item>
                                );
                            })}
                            <Pagination.Next
                                onClick={() => {
                                    updatePageNumber(pageNumber + 1);
                                }}
                                disabled={
                                    pageNumber === maxPageNumber ? true : false
                                }
                            />
                        </Pagination>
                    </Row>
                </div>
            )}
        </div>
    );
}
