import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, collection } from "firebase/firestore";
// import { reCaptcha } from "./reCaptcha";
import { firebaseConfig } from "./firebaseConfig";

export async function submitOrder(purchaseInfo) {
    // await reCaptcha()
    //     .then(() => {
            const app = initializeApp(firebaseConfig);
            const db = getFirestore(app);
            const orderRef = collection(db, "orders");
            return addDoc(orderRef, purchaseInfo);
        // })
        // .catch((err) => {
        //     console.log(err);
        // });
}
