import React from "react";
import { Link } from "react-router-dom";

export default function ButtonLink({ to, ...props }) {
    return (
        <button className="btn btn-primary" {...props}>
            <Link to={to}>{props.children}</Link>
        </button>
    );
}
