import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import ContactModal from "../reusable/ContactModal";

export default function NotFoundPage() {
    const [showContact, setShowContact] = useState(false);

    return (
        <Container className="p-5">
            <ContactModal contactBanner={{ showContact, setShowContact }} />
            <Row>
                <Col>
                    <h1 className="text-center">A kért oldal nem található.</h1>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <p className="text-center">
                        Nem sikerült megtalálnunk az általad keresett oldalt.
                        Amennyiben úgy véled, ennek technikai oka lehet, vedd
                        fel velünk a kapcsolatot{" "}
                        <a
                            href="#"
                            onClick={() => {
                                setShowContact(true);
                            }}
                        >
                            üzenetben
                        </a>
                        .
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
