import React, { useEffect, useState } from "react";
import { Button, Row, Image, Col, ListGroupItem } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { trash2 } from "react-icons-kit/feather";

export default function CartItemWrapper({
    productImage,
    productName,
    sumPrice,
    handleQtyChange,
    productId,
    quantity,
    uid,
}) {
    const [productQuantity, setProductQuantity] = useState(quantity);
    function incrementQuantity() {
        setProductQuantity((productQuantity) => productQuantity + 1);
    }

    function decrementQuantity() {
        setProductQuantity((productQuantity) => productQuantity - 1);
    }

    useEffect(() => {
        handleQtyChange(productQuantity, productId);
    }, [productQuantity]);

    return (
        <ListGroupItem
            action
            href={encodeURI("/vasarlas/" + uid)}
            className="rounded-3 mb-3 border-1"
            variant="light"
        >
            <Row className="align-items-center item-container p-3">
                <Col xs={12} md={3}>
                    <div className="cart-img-container">
                        <Image
                            src={"https:" + productImage + "?w=618&h=410"}
                            alt={productName}
                            fluid
                        />
                    </div>
                </Col>
                <Col xs={12} md={9}>
                    <Row className="align-items-center">
                        <Col md={4} xs={12} className="text-center">
                            {productName}
                        </Col>
                        <Col
                            md={4}
                            xs={12}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <Row>
                                <Col xs={12}>
                                    <div className="d-flex justify-content-center stepper-buttons">
                                        <Button
                                            variant="outline-dark"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                decrementQuantity();
                                            }}
                                        >
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-dash-lg"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                                                    />
                                                </svg>
                                            </span>
                                        </Button>
                                        <div>
                                            <span>{quantity} db</span>
                                        </div>
                                        <Button
                                            variant="outline-dark"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                incrementQuantity();
                                            }}
                                        >
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-lg"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                                    />
                                                </svg>
                                            </span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            md={2}
                            xs={12}
                            className="d-flex justify-content-center align-items-center"
                        >
                            {sumPrice} Ft
                        </Col>
                        <Col
                            xs={12}
                            md={2}
                            className="d-flex justify-content-center"
                        >
                            <Button
                                className="cart-list-remove-desktop"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleQtyChange(0, productId);
                                }}
                            >
                                <Icon icon={trash2} size={24} />
                            </Button>
                            <Button
                                variant="secondary"
                                className="cart-list-remove-mobile"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleQtyChange(0, productId);
                                }}
                            >
                                Törlés
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ListGroupItem>
    );
}
