import React, { useState, useEffect, useContext, useCallback } from "react";
import { Container, Col, Row, Offcanvas, Button } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { filter } from "react-icons-kit/feather";
import ShopContainer from "../ShopContainer";
import Sidebar from "../Sidebar";
import LoadingIcon from "../reusable/LoadingIcon";
import { ProductContext } from "../../context";
import { useHistory } from "react-router";
import queryString from "query-string";

export default function Shop() {
    const history = useHistory();
    const useQuery = useCallback(() => {
        return new URLSearchParams(history.location.search);
    }, []);
    const updateQueryParams = useCallback((category, filter, checked) => {
        const queryParams = queryString.parse(history.location.search);
        if (checked) {
            if (category in queryParams) {
                history.push({
                    search: queryString.stringify({
                        ...queryParams,
                        [category]: [...[queryParams[category]], filter],
                    }),
                });
            } else {
                history.push({
                    search: queryString.stringify({
                        ...queryParams,
                        [category]: filter,
                    }),
                });
            }
        } else {
            if (
                Array.isArray(queryParams[category]) &&
                queryParams[category].length > 1
            ) {
                queryParams[category].splice(
                    queryParams[category].indexOf(filter),
                    1
                );
                history.push({
                    search: queryString.stringify({
                        ...queryParams,
                    }),
                });
            } else {
                delete queryParams[category];
                history.push({
                    search: queryString.stringify({
                        ...queryParams,
                    }),
                });
            }
        }
    }, []);

    let query = useQuery();
    const { client } = useContext(ProductContext);
    useEffect(() => {
        client.getContentType("product").catch(console.error);
    }, []);
    const [productList, setProductList] = useState([]);
    const [filterStatus, setFilterStatus] = useState(false);
    const [totalResults, setTotalResults] = useState(null);
    const [isProductsLoading, setIsProductsLoading] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const itemsLimit = 20;
    const [selectedFilters, setSelectedFilters] = useState({
        content_type: "product",
        category: query.getAll("category"),
        type: query.getAll("type"),
        material: query.getAll("material"),
        pattern: query.getAll("pattern"),
        color: query.getAll("color"),
    });
    const [filterRequest, setFilterRequest] = useState({
        content_type: "product",
        skip: (pageNumber - 1) * itemsLimit,
        limit: itemsLimit,
    });

    function generateRequest() {
        setFilterRequest({
            content_type: "product",
            skip: (pageNumber - 1) * itemsLimit,
            limit: itemsLimit,
            ...(selectedFilters["category"].length === 0
                ? {}
                : {
                      "fields.category[in]":
                          selectedFilters["category"].toString(),
                  }),
            ...(selectedFilters["type"].length === 0
                ? {}
                : {
                      "fields.type[in]": selectedFilters["type"].toString(),
                  }),
            ...(selectedFilters["material"].length === 0
                ? {}
                : {
                      "fields.material[in]":
                          selectedFilters["material"].toString(),
                  }),
            "fields.color.sys.contentType.sys.id": "color",
            ...(selectedFilters["color"].length === 0
                ? {}
                : {
                      "fields.color.fields.value[in]":
                          selectedFilters["color"].toString(),
                  }),
            "fields.pattern.sys.contentType.sys.id": "pattern",
            ...(selectedFilters["pattern"].length === 0
                ? {}
                : {
                      "fields.pattern.fields.value[in]":
                          selectedFilters["pattern"].toString(),
                  }),
        });
    }

    useEffect(() => {
        generateRequest();
    }, [selectedFilters, pageNumber]);

    useEffect(() => {
        setIsProductsLoading(true);
        client.getEntries(filterRequest).then((response) => {
            setIsProductsLoading(false);
            setProductList(response.items);
            setTotalResults(response.total);
        });
    }, [filterRequest]);

    function filterHandler(name, value) {
        if (!selectedFilters[name].includes(value)) {
            if (selectedFilters[name].length === 1) {
                setSelectedFilters((selectedFilters) => ({
                    ...selectedFilters,
                    [name]: [...selectedFilters[name], value],
                }));
            } else {
                setSelectedFilters((selectedFilters) => ({
                    ...selectedFilters,
                    [name]: [...selectedFilters[name], value],
                }));
            }
        } else {
            setSelectedFilters((selectedFilters) => ({
                ...selectedFilters,
                [name]: selectedFilters[name].filter((item) => item !== value),
            }));
        }
    }

    return (
        <Container fluid="md">
            {isProductsLoading && totalResults == null ? (
                <LoadingIcon />
            ) : (
                <Row id="shop">
                    <div
                        className="sidebar-toggle"
                        onClick={() => setShowSidebar(true)}
                    >
                        <Row className="my-3 align-items-center">
                            <Col className="text-start d-flex">
                                <Button variant="light">
                                    <Icon icon={filter} size={24} />
                                </Button>
                                <div className="align-self-center">
                                    Szűrők megjelenítése
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Col className="sidebar-content" lg={3} xs={12}>
                        <Offcanvas
                            responsive="md"
                            show={showSidebar}
                            onHide={() => setShowSidebar(false)}
                            closeButton
                        >
                            <Offcanvas.Body className="sidebar-body">
                                <Offcanvas.Header closeButton>
                                    <h3>Szűrők</h3>
                                </Offcanvas.Header>
                                <Sidebar
                                    props={{
                                        setFilterStatus,
                                        filterStatus,
                                        filterHandler,
                                        selectedFilters,
                                        updateQueryParams,
                                    }}
                                />
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Col>
                    <Col className="shop-content" lg={9} xs={12}>
                        <ShopContainer
                            props={{
                                productList,
                                pageNumber,
                                setPageNumber,
                                totalResults,
                                itemsLimit,
                                isProductsLoading,
                            }}
                        />
                    </Col>
                </Row>
            )}
        </Container>
    );
}
