import React from "react";
import Home from "./components/pages/Home";
import Shop from "./components/pages/Shop";
import ProductPage from "./components/pages/ProductPage";
import CheckoutPage from "./components/pages/CheckoutPage";
import Contacts from "./components/pages/Contacts";
import NotFoundPage from "./components/pages/NotFoundPage";
import ASZF from "./components/pages/ASZF";
import Data from "./components/pages/Data";
import Navigation from "./components/Navbar/Navigation";
import Footer from "./components/Footer";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./Styles/App.scss";

function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Navigation />
                <main>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/vasarlas">
                            <Shop />
                        </Route>
                        <Route
                            exact
                            path="/vasarlas/:uid"
                            render={(props) => <ProductPage {...props} />}
                        />
                        <Route exact path="/elerhetosegek">
                            <Contacts />
                        </Route>
                        <Route path="/fizetes">
                            <CheckoutPage />
                        </Route>
                        <Route exact path="/szerzodesi-feltetelek">
                            <ASZF />
                        </Route>
                        <Route exact path="/adatkezelesi-tajekoztato">
                            <Data />
                        </Route>
                        <Route path="/404">
                            <NotFoundPage />
                        </Route>
                        <Redirect from="*" to="/404" />
                    </Switch>
                </main>
                <Footer />
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
