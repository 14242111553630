import React from "react";
import { createPortal } from "react-dom";
import CheckoutProducts from "./CheckoutProducts";
import { Offcanvas } from "react-bootstrap";

export default function CartSlideIn({
    props: { cartSlideIn, handleCartClose },
}) {
    if (!cartSlideIn) return null;
    return createPortal(
        <div>
            <Offcanvas
                show={cartSlideIn}
                placement="end"
                onHide={handleCartClose}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvas-checkout-container">
                    <CheckoutProducts handleCartClose={handleCartClose} />
                </Offcanvas.Body>
            </Offcanvas>
        </div>,
        document.body
    );
}
