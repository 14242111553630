import React from "react";
import { Row, Col, Image, ListGroup } from "react-bootstrap";

export default function SearchItem({ prodImg, name, price, url }) {
    return (
        <ListGroup.Item action href={"/vasarlas/" + url}>
            <Row className="align-items-center">
                <Col xs={3} className="mb-2">
                    <Image src={"https:" + prodImg + "?w=211&h=139"} fluid />
                </Col>
                <Col xs={9}>
                    <Row>
                        <Col xs={12}>{name}</Col>
                        <Col xs={12}>{price} Ft</Col>
                    </Row>
                </Col>
            </Row>
        </ListGroup.Item>
    );
}
