import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import NewestProduct from "./reusable/NewestProduct";

export default function NewestWrapper() {
    return (
        <Container className='newest-products-container py-3' fluid>
            <div className='header-text'>
                <h2>Újdonságaink</h2>
            </div>
            <Container>
                <Row className='newproducts-catalog justify-content-center'>
                    <Col md={6}>
                        <NewestProduct />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
