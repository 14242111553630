import React from "react";
import { Container } from "react-bootstrap";

const css = `
p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: center; font: 16.0px 'Times New Roman'}
p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'; min-height: 15.0px}
p.p3 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'}
p.p4 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px 'Times New Roman'; min-height: 15.0px}
p.p5 {margin: 0.0px 0.0px 0.0px 18.0px; text-align: justify; font: 16.0px 'Times New Roman'; min-height: 15.0px}
p.p6 {margin: 0.0px 0.0px 0.0px 35.4px; text-align: justify; font: 16.0px 'Times New Roman'}
p.p7 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px 'Times New Roman'}
p.p8 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'; color: #000000}
p.p9 {margin: 0.0px 0.0px 0.0px 213.0px; font: 16.0px 'Times New Roman'; min-height: 15.0px}
li.li3 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 16.0px 'Times New Roman'}
li.li7 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px 'Times New Roman'}
span.s1 {text-decoration: underline}
span.s2 {color: #6f6f65}
span.s3 {color: #000000}
table.t1 {border-collapse: collapse}
td.td1 {border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #bfbfbf #bfbfbf #bfbfbf #bfbfbf; padding: 0.0px 5.0px 0.0px 5.0px}
ul.ul1 {list-style-type: disc}

`;

export default function ASZF() {
    return (
        <Container className="pt-5">
            <style>{css}</style>
            <p className="p1">
                <b>ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK (ÁSZF)</b>
            </p>
            <p className="p1">
                https://hopekezmuves.hu/ - hatályos 2022. október 1. napjától
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Tájékoztatjuk, hogy Ön mint vásárló a{" "}
                <a href="https://hopekezmuves.hu/">https://hopekezmuves.hu/</a>{" "}
                címen elérhető honlap használatával kinyilvánítja, hogy ismeri
                és elfogadja az alábbi, a Ptk. (2013. évi V. törvény)
                6:77-6:81.§ alapján megírt általános szerződési feltételeket.
                Kérjük, hogy körültekintően olvassa el az Általános Szerződési
                Feltételeinket és kizárólag abban az esetben vegye igénybe
                szolgáltatásainkat, amennyiben annak minden pontjával egyetért,
                és azokat kötelező érvényűnek ismeri el magára nézve.
            </p>
            <p className="p3">
                Ha a jelen Általános Szerződési Feltételekkel, a honlap
                használatával, az egyes termékekkel, a vásárlás menetével
                kapcsolatban kérdése merült fel, vagy amennyiben egyedi igényét
                szeretné velünk megbeszélni, kérjük, vegye fel velünk a
                kapcsolatot a megadott elérhetőségeken!
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    A Szolgáltató (Vállalkozás, Eladó) adatai
                </li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Név: Ho-Pe 2002 Oktatási és Szolgáltató Betéti Társaság
            </p>
            <p className="p3">
                Székhely: 1035 Budapest, Szél utca 17. 7. em. 38.
            </p>
            <p className="p3">
                Levelezési cím: 1035 Budapest, Szél utca 17. 7. em. 38.
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p3">
                Nyilvántartásba vevő hatóság: Fővárosi Törvényszék Cégbírósága
            </p>
            <p className="p3">
                Cégjegyzékszám: 01-06-749191{" "}
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p3">Adószám: 21354452-1-41</p>
            <p className="p3">Vezető tisztségviselő: Hogyor Zsuzsanna</p>
            <p className="p3">Telefonszám: +36309668651</p>
            <p className="p3">
                E-mail:{" "}
                <a href="mailto:hopekezmuves18@gmail.com">
                    hopekezmuves18@gmail.com
                </a>
            </p>
            <p className="p3">
                Honlap: <a href="">hopekezmuves.hu</a>
            </p>
            <p className="p3">Bankszámlaszám: 65700127-10121681 (MBH Bank)</p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Alapvető rendelkezések</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A Honlapon közölt információk nem minősülnek a Szolgáltató
                részéről szerződés megkötésére vonatkozó ajánlatnak. Jelen ÁSZF
                hatálya alá tartozó megrendelések esetén a jogszabály alapján Ön
                minősül ajánlattevőnek, a Szolgáltató az Ön ajánlatát elfogadja
                és így jön létre közöttünk a szerződés.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Honlapon keresztül leadott megrendelés nem írásban, hanem
                ráutaló magatartással tett jognyilatkozatnak minősül, így az Ön
                és a Szolgáltató közötti elektronikus úton kötött szerződés nem
                minősül írásbeli szerződésnek, azokat a Szolgáltató nem iktatja,
                így az utólag nem hozzáférhető és nem megtekinthető.
            </p>
            <p className="p4">
                <br />
            </p>
            <p className="p3">
                Ön a Honlap használata során tanúsított ráutaló magatartásával
                (pl. jelölőnégyzet bejelölése és/vagy megrendelést indító gomb
                leütése) tudomásul veszi és elfogadja a jelen ÁSZF-ben
                foglaltakat és a konkrét Termék(ek) megvásárlására vonatkozó
                egyedi feltételeket.
            </p>
            <p className="p4">
                <br />
            </p>
            <p className="p3">A szerződés nyelve magyar.</p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>A webshop, mint szerzői jogi mű szerzői jogi védelme</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A honlap egésze, így különösen annak grafikus elemei, szövege,
                és technikai megoldásai szerzői jogi védelem vagy más szellemi
                alkotáshoz fűződő jog alatt állnak.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Megvásárolható termékek, szolgáltatások köre</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Ékszerek, textíliák és egyéb kézműves termékek. A termékkínálat
                folyamatosan bővül.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Webshopon látható termékfotók megjelenítésével törekszünk
                arra, hogy azok minél élethűbb megjelenést biztosítsanak. Ennek
                ellenére előfordulhat, hogy a termékfotók színei az emberi szem
                számára eltérően jelennek meg, mint az informatikai eszközökön.
                Ezen eltérések miatt a Szolgáltató nem vállal felelősséget.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>A megrendelés folyamata</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A megrendelni kívánt termék vagy szolgáltatás lényeges
                tulajdonságairól, vételárának teljes összegéről a Vásárló a
                termék nevére vagy a terméket ábrázoló képre történő kattintást
                követően tájékozódhat.{" "}
            </p>
            <br />

            <p className="p3">
                Ha bármely Termék elnyeri tetszését, úgy a „Kosárba” feliratú
                szóra kattintva tudja a Terméke(ke)t virtuális kosarába
                helyezni.
            </p>
            <br />

            <p className="p3">
                Amikor a Vásárló a terméket a kosarába helyezi, a honlap jobb
                felső sarkában a kosár ikon melletti szám jelzi, hogy a termék
                elhelyezésre került a virtuális kosárba.
            </p>
            <br />

            <p className="p3">
                A kosár tartalmát a honlap jobb felső sarkán látható kosár
                ikonra kattintva tudja megtekinteni. A kosárba rakott Termék(ek)
                darabszámát a megrendelés végleges leadása előtt Ön tetszés
                szerint csökkentheti, növelheti vagy törölheti a kosárból.
            </p>
            <br />

            <p className="p3">
                Megrendelésének indításához Önnek a kosár ikonra kattintást
                követően a „Tovább a fizetéshez” lehetőséget kell kiválasztania.
            </p>
            <br />

            <p className="p3">
                Az előző pontban írt lépést követően meg kell adnia adatait a
                Honlapon található webűrlap kitöltésével.
            </p>
            <br />

            <p className="p3">
                Ezt követően ki kell választania a felsorolt lehetőségek közül a
                kézbesítés, majd a fizetés módját
            </p>
            <br />

            <p className="p3">
                A következő oldalon van lehetősége módosítani a kosárba rakott
                Termék(ek) darabszámát, illetve törölheti is az(oka)t a
                kosárból. Ezt követően a "Rendelés elküldése" gombra kattintva
                véglegesíti a rendelését.
            </p>
            <br />

            <p className="p3">
                Amennyiben a rendelés sikeres volt, a felület továbbítja Önt egy
                megerősítő oldalra. A fizetés és kézbesítés további részleteivel
                kapcsolatban e-mailben vesszük fel a kapcsolatot Önnel.
            </p>

            <p className="p2">
                <br />
            </p>
            <p className="p4">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>
                        A rendelés visszaigazolásának folyamata, a rendelés
                        feldolgozása
                    </b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A rendelés feldolgozása két lépcsőben történik.
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p5">
                <br />
            </p>
            <p className="p3">
                Az első email egy automatikus visszaigazoló e-mail, amelyben
                arról kap tájékoztatást, hogy rendelése megérkezett a
                rendszerbe. Ez egy technikai jellegű rendszerüzenet, amely
                tartalmazza az Ön által megadott adatokat (a rendelés
                azonosítóját, a rendelés dátumát, a megrendelt Termékek
                felsorolását, mennyiségét, a Termék árát, szállítási költséget
                és a fizetendő végösszeget). Ez a visszaigazolás nem minősül az
                ajánlat elfogadásának.
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p4">
                <br />
            </p>
            <p className="p3">
                A második e-mail már nem automatikusan küldött, technikai
                jellegű rendszerüzenet. Ez az e-mail tartalmazza a Szolgáltató
                szerződéses (azaz az Ön megrendelését elfogadó) nyilatkozatát,
                amely a megrendelés elküldésének időpontjától számított
                legkésőbb 48 órán belül visszaigazolja a megrendelés
                teljesíthetőségét. Az ajánlatot elfogadó, második e-mail a
                Szolgáltató részéről az Ön által tett ajánlat elfogadásának
                minősül, amellyel létrejön a szerződés Ön és a Szolgáltató
                között.
            </p>
            <p className="p4">
                <br />
            </p>
            <p className="p3">
                Az Ön és a Szolgáltató között online módon megkötött Termék
                adásvételére vonatkozó szerződés elektronikus úton megkötött
                szerződésnek minősül. Az adásvételi szerződés alapján a
                Szolgáltató dolog tulajdonjogának átruházására, Ön a vételár
                megfizetésére és a dolog átvételére köteles.
            </p>
            <p className="p4">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Ajánlati kötöttség</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A Vásárló mentesül az ajánlati kötöttség alól, ha késedelem
                nélkül (de legkésőbb 48 órán belül) nem kapja meg a
                Szolgáltatótól az elküldött rendelésére vonatkozó visszaigazoló
                e-mailt.<span className="Apple-converted-space"> </span>
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Amennyiben a visszaigazolás azért nem érkezik meg időben, mert
                Ön hibás e-mail címet adott meg a megrendelési folyamat során,
                vagy a fiókjához tartozó tárhely telítettsége miatt Ön nem tud
                további e-maileket fogadni, a Szolgáltató kizárja a felelősségét
                az Ön megrendelésének meghiúsulásáért és a szerződés
                létrejöttének elmaradásáért.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Ha a Vásárló a visszaigazoló e-mailben szereplő adatok
                tekintetében hibát észlel, azt a visszaigazoló e-mail
                beérkezését követő 24 órán belül jeleznie kell a Szolgáltató
                felé (elektronikus levél útján, vagy telefonon). E határidő
                elteltét követően a megrendelést visszaigazoló e-mailt a
                Szolgáltató akként tekinti, hogy az abban foglalt adatok a
                valóságnak megfelelnek és a Vásárló által nem kifogásoltak.
            </p>
            <p className="p4">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Árak</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Az árak forintban értendők. Nem zárható ki annak a lehetősége,
                hogy üzletpolitikai okból az Eladó az árakat módosítsa. Az árak
                módosítása nem terjed ki a már megkötött szerződésekre.
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Részleges érvénytelenség, magatartási kódex</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Ha az ÁSZF valamely pontja jogilag hiányos vagy hatálytalan,
                attól még a szerződés további pontjai érvényben maradnak és a
                hatálytalan vagy hibás rész helyett a vonatkozó jogszabályok
                rendelkezései alkalmazandóak.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Az Eladónak nincs a fogyasztókkal szembeni tisztességtelen
                kereskedelmi gyakorlat tilalmáról szóló törvény szerinti
                magatartási kódexe.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Teljesítési határidő</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A megrendelésre vonatkozóan az általános teljesítési határidő a
                rendelés visszaigazolásától számított legfeljebb 8 nap. Az Eladó
                késedelme esetén a Vevő jogosult póthatáridőt tűzni. Ha az Eladó
                a póthatáridőn belül nem teljesít, a Vevő jogosult a
                szerződéstől elállni. A teljesítési határidő külföldre történő
                értékesítéskor eltérhet.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>
                        Tájékoztató a fogyasztó vevőt megillető elállási jogról
                    </b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A 45/2014. (II. 26.) Korm. rendelet értelmében a Felhasználó
                indoklás nélkül elállhat a vásárlástól a kézhezvételtől
                számított 14 napon belül. Kézhezvételnek házhoz szállítás esetén
                a futártól való átvétel napját, személyes átvételnél a
                Szolgáltatótól való átvételt időpontját értjük. Az elállási
                határidő a kézhezvételtől számított 14 nap elteltével jár le.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Elállási jog gyakorlásának menete:</b>
            </p>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Fogyasztó a szerződés megkötésének a napja és a termék
                átvételének napja közötti időszakban is gyakorolja elállási
                jogát.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Ha a Felhasználó elállási jogával élni kíván, elállási szándékát
                tartalmazó egyértelmű nyilatkozatát köteles eljuttatni (például
                postán, telefaxon vagy elektronikus úton küldött levél útján) a
                jelen ÁSZF 1. pontjában feltüntetett elérhetőségek
                igénybevételével a Szolgáltató részére. Felhasználó határidőben
                gyakorolja elállási jogát, ha a fent megjelölt határidő lejárta
                előtt elküldi elállási nyilatkozatát a Szolgáltató részére.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Felhasználót terheli annak bizonyítása, hogy elállási jogát az
                ÁSZF-ben meghatározott rendelkezéseknek megfelelően gyakorolta.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Minden esetben a Szolgáltató emailben haladéktalanul
                visszaigazolja a Felhasználó elállási nyilatkozatának
                megérkezését.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Írásban történő elállás esetén azt határidőben érvényesítettnek
                kell tekinteni, ha Felhasználó az erre irányuló nyilatkozatát 14
                naptári napon belül (akár a 14. naptári napon) elküldi a
                Szolgáltatónak.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Postai úton történő jelzés alkalmával a postára adás dátumát,
                e-mail vagy telefaxon keresztül történő értesítés esetén az
                e-mail illetve a fax küldésének idejét veszi figyelembe
                Szolgáltató a határidő számítás szempontjából. A Felhasználó
                levelét ajánlott küldeményként adja postára, hogy hitelt
                érdemlően bizonyítható legyen a feladás dátuma.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Felhasználó elállás esetén köteles a megrendelt terméket a
                Szolgáltató 1. pontban feltüntetett címére indokolatlan
                késedelem nélkül, de legkésőbb elállási nyilatkozatának
                közlésétől számított 14 napon belül visszaküldeni. A határidő
                betartottnak minősül, ha Felhasználó a 14 napos határidő letelte
                előtt elküldi (postára adja vagy az általa megrendelt futárnak
                átadja) a terméket.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A termék Szolgáltató címére történő visszaküldésének költsége
                Felhasználót terheli. Szolgáltatónak az utánvéttel visszaküldött
                csomagot nem áll módjában átvenni. A termék visszaküldésének
                költségén kívül az elállás kapcsán a Felhasználót semmilyen más
                költség nem terheli.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Ha Felhasználó eláll a szerződéstől, haladéktalanul, de
                legkésőbb a Felhasználó elállási nyilatkozatának kézhezvételétől
                számított 14 napon belül Szolgáltató visszatéríti a Felhasználó
                által teljesített valamennyi ellenszolgáltatást, ideértve a
                fuvarozási (kiszállításért fizetett) költséget is, kivéve azokat
                a többletköltségeket, amelyek amiatt merültek fel, hogy
                Felhasználó a Szolgáltató által felkínált, legolcsóbb szokásos
                fuvarozási módtól eltérő fuvarozási módot választott.
                Szolgáltató jogosult a visszatérítést mindaddig visszatartani,
                amíg vissza nem kapta a terméket, vagy Felhasználó nem igazolta
                hitelt érdemlően, hogy azt visszaküldte: a kettő közül
                Szolgáltató a korábbi időpontot veszi figyelembe.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A visszatérítés során az eredeti ügylet során alkalmazott
                fizetési móddal egyező fizetési módot alkalmaz a Szolgáltató,
                kivéve, ha Felhasználó más fizetési mód igénybevételéhez
                kifejezetten a hozzájárulását adja; e visszatérítési mód
                alkalmazásából kifolyólag Felhasználót semmilyen többletköltség
                nem terheli.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Felhasználó kizárólag akkor vonható felelősségre a termékben
                bekövetkezett értékcsökkenésért, ha az a termék jellegének,
                tulajdonságainak és működésének megállapításához szükséges
                használatot meghaladó használat miatt következett be.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Fogyasztót mely esetekben nem illeti meg az elállási jog:</b>
            </p>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Az elállás joga nem gyakorolható olyan áru értékesítése
                esetében:
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p6">
                -<span className="Apple-converted-space"> </span>amely a
                fogyasztó személyéhez kötött, illetve amelyet a fogyasztó
                utasításai alapján vagy kifejezett kérésére állítottak elő, vagy
                amely természeténél fogva nem szolgáltatható vissza;
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b> Szavatosság</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                <b>Kellékszavatosság:</b>
            </p>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Az Eladó hibás teljesítése esetén a Vásárló kellékszavatossági
                igényt érvényesíthet a Polgári Törvénykönyv szabályai szerint.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Vásárló köteles a hibát annak felfedezése után haladéktalanul,
                de nem később, mint a hiba felfedezésétől számított kettő
                hónapon belül közölni.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Vásárló a fogyasztói szerződés esetén az átvétel időpontjától
                számított 2 éven belül érvényesítheti szavatossági igényeit,
                azokért a termékhibákért, amelyek a termék átadása időpontjában
                már léteztek. Két éves elévülési határidőn túl a vásárló
                kellékszavatossági nem tudja érvényesíteni.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Vásárló a kellékszavatossági jog érvényesítése esetén kérhet
                kijavítást vagy kicserélést, kivéve, ha az ezek közül az általa
                választott igény teljesítése lehetetlen vagy az Eladó számára
                más igény teljesítéséhez képest aránytalan többletköltséggel
                járna.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Ha Vásárló a kijavítást vagy a kicserélést nem kérte, illetve
                nem kérhette, úgy igényelheti az ellenszolgáltatás arányos
                leszállítását vagy a hibát az Eladó költségére kijavíthatja,
                illetve mással kijavíttathatja vagy –végső esetben – a
                szerződéstől is elállhat.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Választott kellékszavatossági jogáról a Vásárló egy másikra is
                áttérhet, ebben az esetben viszont köteles az áttérés költségét
                viselni kivéve, ha az indokolt volt, vagy arra az Eladó adott
                okot.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A teljesítéstől számított hat hónapon belül a kellékszavatossági
                igény érvényesítésének a hiba közlésén túl nincs egyéb feltétele
                amennyiben a Vásárló igazolja, hogy a terméket az Eladótól
                vásárolta. A teljesítéstől számított hat hónap eltelte után a
                Vásárló köteles bizonyítani, hogy az általa felismert hiba már a
                teljesítés időpontjában is megvolt.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Termékszavatosság:</b>
            </p>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A termék abban az esetben tekinthető hibásnak, ha az nem felel
                meg a forgalomba hozatalakor hatályos minőségi követelményeknek
                vagy pedig, ha nem rendelkezik a gyártó által adott leírásban
                szereplő tulajdonságokkal. Termékszavatossági igényét a Vásárló
                a termék gyártó általi forgalomba hozatalától számított két éven
                belül érvényesítheti. E határidő elteltével a Vevő e
                jogosultságát elveszti.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Termékszavatossági igényét a Vásárló kizárólag az ingó dolog
                gyártójával vagy az Eladóval szemben gyakorolhatja. A termék
                hibáját termékszavatossági igény érvényesítése esetén a
                Vásárlónak kell bizonyítania. A gyártó (forgalmazó) kizárólag
                akkor mentesül termékszavatossági kötelezettsége alól, ha
                bizonyítani tudja, hogy:
            </p>
            <p className="p3">
                - a terméket nem üzleti tevékenysége körében gyártotta, illetve
                hozta forgalomba,
            </p>
            <p className="p3">
                - a hiba a tudomány és a technika állása szerint a forgalomba
                hozatal időpontjában nem volt
            </p>
            <p className="p3">
                <span className="Apple-converted-space"> </span>felismerhető
                vagy
            </p>
            <p className="p3">
                <span className="Apple-converted-space"> </span>- a termék
                hibája jogszabály vagy kötelező hatósági előírás alkalmazásából
                ered.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Vásárló ugyanazon hiba miatt kellékszavatossági és
                termékszavatossági igényt egyszerre, egymással párhuzamosan nem
                érvényesíthet.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <b>Jótállás:</b>
            </p>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Szolgáltató nem árusít az egyes tartós fogyasztási cikkekre
                vonatkozó kötelező jótállásról szóló 151/2003. (IX. 22.) Korm.
                rendeletben felsorolt új tartós fogyasztási cikkeket (pl.
                műszaki cikkek, szerszámok, gépek),<span className="s2"> </span>
                így nem köteles jótállásra hibás teljesítés esetén.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b> Jogérvényesítési lehetőségek</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p7">
                <b>
                    Panaszügyintézés
                    <span className="Apple-converted-space"> </span>
                </b>
            </p>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Ön panaszt tehet a Szolgáltatónál a Szolgáltató magatartására,
                tevékenységére vagy mulasztására, ami a Termék(ek)nek a Vásárló
                részére történő forgalmazásával, illetve értékesítésével
                közvetlen kapcsolatban áll.
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">Ön a panaszát szóban és írásban is megteheti.</p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Ön a Termékkel vagy a Szolgáltató értékesítési tevékenységével
                kapcsolatos fogyasztói kifogásait elsősorban közvetlenül a 1.
                pontban meghatározott elérhetőségeken keresztül terjesztheti
                elő.
            </p>
            <p className="p7">Szóbeli panasz</p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Szolgáltató a telefonon keresztül közölt szóbeli panaszt
                köteles azonnal megvizsgálni és amennyiben arra lehetősége van,
                szükség szerint orvosolni.
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Egyekekben a szóbeli panasz feldolgozásakor a Vállalkozás az
                írásbeli panaszra vonatkozó szabályok szerint jár el.
            </p>
            <p className="p7">Írásbeli panasz</p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A Szolgáltató az írásbeli panaszt 30 napon belül érdemben
                megválaszolja és intézkedik annak közléséről.{" "}
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p8">
                <span className="s3">
                    A panasz elutasítása esetén a Szolgáltató indokolással
                    együtt tájékoztatja Önt.{" "}
                </span>
                A panasz elutasítása esetén a Szolgáltató köteles Vevőt írásban
                tájékoztatni arról, hogy panaszával mely hatóság vagy békéltető
                testület eljárását kezdeményezheti.
            </p>
            <p className="p7">Egyéb jogérvényesítési lehetőségek</p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                A fogyasztónak minősülő Vásárló, a lakóhelye vagy tartózkodási
                helye szerint illetékes békéltető testülethez fordulhat és
                kezdeményezheti a testület eljárását, illetve fordulhat a
                Vállalkozás székhelye szerint illetékes békéltető testülethez
                is, továbbá a következő jogérvényesítési lehetőségek állnak
                nyitva a Vásárló számára:
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">Békéltető testületi eljárás</li>
                <li className="li3">
                    Panasztétel a fogyasztóvédelmi hatóságnál
                </li>
                <li className="li3">
                    Vitarendezési eljárás az EU online vitarendezési platformján
                    keresztül
                </li>
                <li className="li3">Bírósági eljárás kezdeményezése</li>
            </ul>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>
                        Az Általános Szerződési Feltételek egyoldalú módosítása
                    </b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                Szolgáltató jogosult jelen Általános Szerződési Feltételeket a
                Felhasználók Honlapon történő előzetes tájékoztatása mellett,
                egyoldalúan is módosítani. A módosított rendelkezések a hatályba
                lépést követően a Honlap első használata alkalmával válnak
                hatályossá Felhasználóval szemben, azokat a módosítást követően
                leadott megrendelésekre kell alkalmazni.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Tulajdonjog fenntartása</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A vételár teljes kifizetéséig a termék a Szolgáltató
                tulajdonában marad. Ha a termék a vételár teljes kifizetését
                megelőzően mégis – bármely okból – a Felhasználó birtokába
                kerül, felelősséggel tartozik a Szolgáltató irányába mindazon
                károk tekintetében, amelyek megtérítésére senkit nem lehet
                kötelezni.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b> Technikai korlátok</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A honlapon történő vásárlás feltételezi a Felhasználó részéről
                az Internet lehetőségeinek és korlátainak ismeretét és
                elfogadását, különös tekintettel a technikai teljesítményekre és
                a felmerülő hibákra. A Szolgáltatót nem terheli felelősség, ha
                bármilyen működési hiba észlelhető az internetes hálózatban, ami
                megakadályozza a honlap működését és a vásárlást.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b> ÁSZF, árak módosítása</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A Szolgáltató jelen ÁSZF-et, a honlapon forgalmazott termékek
                árait és egyéb feltüntetett árakat, nem visszamenőleges
                hatállyal, bármikor módosíthatja, a módosítás a honlapon történő
                közzétételt követően lép életbe, és csak az életbe lépést követő
                tranzakciókra érvényes.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Felelősség</b>
                </li>
            </ul>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A Szolgáltató nem vonható felelősségre semmilyen kárért, amelyet
                a Vásárló vagy bármely harmadik fél elszenvedhet annak
                eredményeként, hogy a Szolgáltató teljesíti a Rendelés szerinti
                bármely kötelezettségét és nem vonható felelősségre semmilyen
                kárért, amely a Termékek Vásárló részére történő átadása után,
                azok használatából származnak.
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Eladó nem vonható felelősségre a Termékek szállítás közbeni
                elveszítéséért, a szállításból eredő bármilyen probléma a
                futárszolgálat/gyorsposta felelősségi körébe tartozik.
            </p>
            <p className="p2">
                <br />
            </p>
            <ul className="ul1">
                <li className="li3">
                    <b>Záró rendelkezések</b>
                </li>
            </ul>
            <p className="p4">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A jelen Szabályzatban nem szabályozott kérdésekre, valamint
                jelen Szabályzat értelmezésére a magyar jog az irányadó, különös
                tekintettel a Polgári Törvénykönyvről szóló 2013. évi V. törvény
                („Ptk.”) vonatkozó rendelkezéseire. A vonatkozó jogszabályok
                kötelező rendelkezései a felekre külön kikötés nélkül is
                irányadók.<b></b>
            </p>
            <ul className="ul1">
                <li className="li7">
                    <b>Melléklet</b>
                </li>
            </ul>
            <p className="p9">
                <b></b>
                <br />
            </p>
            <p className="p1">
                <b>Elállási nyilatkozat minta</b>
            </p>
            <p className="p2">
                <b></b>
                <br />
            </p>
            <p className="p3">
                A szerződéstől való elállási szándék esetén töltse ki és
                juttassa vissza az alább feltüntetett elérhetőség valamelyikére.
            </p>
            <p className="p4">
                <br />
            </p>
            <p className="p7">Vállalkozás adatai:</p>
            <p className="p2">
                <br />
            </p>
            <table cellSpacing="0" cellPadding="0" className="t1">
                <tbody>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p8">
                                <b>Cégnév:</b>
                            </p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p7">
                                Ho-Pe 2002 Oktatási és Szolgáltató Betéti
                                Társaság
                            </p>
                            <p className="p4">
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p8">
                                <b>Székhely:</b>
                            </p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p7">
                                1035 Budapest, Szél utca 17. 7. em. 38.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p8">
                                <b>Telefon:</b>
                            </p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p7">+36309668651</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p8">
                                <b>E-mail:</b>
                            </p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p7">hopekezmuves18@gmail.com</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="middle" className="td1">
                            <p className="p8">
                                <b>Adószám:</b>
                            </p>
                        </td>
                        <td valign="middle" className="td1">
                            <p className="p7">21354452-1-41</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="p4">
                <br />
            </p>
            <p className="p3">
                Alulírott, ……………………….. kijelentem, hogy gyakorlom elállási
                jogomat a Vállalkozással az alábbi termék/ek adásvételével
                kapcsolatban kötött szerződés tekintetében:
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">Megrendelés száma:………………………………………………………….</p>
            <p className="p3">Termék megnevezése:……………………………………………………….</p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">Szerződéskötés időpontja /átvétel időpontja:</p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Elállást gyakorló fogyasztó
                neve:..........................................................................................
            </p>
            <p className="p3">
                Elállást gyakorló fogyasztó
                címe:....................................................................................…..
            </p>
            <p className="p3">
                ....................................................................................................................................................
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Amennyiben az alábbi adatokat megadja, hozzájárul ahhoz, hogy
                Vállalkozás az Ön által kifizetett végösszeget átutalás
                formájában térítse vissza az Ön részére:
                <span className="Apple-converted-space"> </span>
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Kedvezményezett
                neve:………………………………..............................................................
            </p>
            <p className="p3">
                Bankszámlaszám:………………………………….............................................................…..
            </p>
            <p className="p3">
                Az Ön vásárláskor/regisztrációkor megadott email címe:
                .........................................................
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                Kelt ………...................................,
                ....................év............................hónap……………..nap
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                ……………………………...….....................................................................................
            </p>
            <p className="p3">Fogyasztó aláírása</p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
        </Container>
    );
}
